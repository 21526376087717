import axios from 'axios'
import LocaleHelper from './LocaleHelper';

const baseDomain = process.env.VUE_APP_BASE_API_URL
const BASE_URL = `${baseDomain}/api`
const DEBUG = process.env.NODE_ENV === 'development'
const LOCAL = LocaleHelper.getCurrentLocale();

const sharedHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
  , 'lang': LOCAL=='en' ? 'en_US':'ar_SY'

}
//, 'lang': LOCAL=='en' ? 'en_US':'ar_SY'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: sharedHeaders,
  https: !DEBUG
})
export default axiosInstance
