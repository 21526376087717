<template>
    <footer class="container-fluid d-flex flex-column justify-content-between" id="footer" v-if="xl || lg">
        <hr class="border-color-blue my-0">
        <div class="row px-80px position-relative text-start h-fit-content">
            <div class="col-2 px-0 h-fit-content mt-50">
                <img :src="require(`@/assets/img/footer-img-${getCurrentLang}.svg`)" class="w-85" alt="">
            </div>
            <div class="col-10 px-0 h-fit-content my-auto d-flex justify-content-between">
                <div class="section d-flex flex-column ms-5">
                    <a type="button" @click="navigateTo('about-section')" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('about-jumlatech') }}
                    </a>
                    <a type="button" @click="navigateTo('how-section')" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('how-to-work') }}
                    </a>
                    <a type="button" @click="navigateTo('partners-section')" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('our-partners') }}
                    </a>
                </div>
                <div class="section d-flex flex-column">
                    <a type="button" @click="navigateTo('who-section')" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('who-are-we') }}
                    </a>
                    <a href="jobs" target="_blank" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('jobs-title') }}
                    </a>
                    <a type="button" @click="navigateTo('download-section')" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('download-the-app') }}
                    </a>
                </div>
                <div class="section d-flex flex-column">
                    <router-link :to="{ name: 'about-app' }" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('about-the-app') }}
                    </router-link>
                    <router-link :to="{ name: 'terms-and-conditions' }" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('terms-and-conditions') }}
                    </router-link>
                    <router-link :to="{ name: 'privacy-policy' }" class="f-20 medium mb-3 white hide-underline">
                        {{ $t('privacy-policy') }}
                    </router-link>
                </div>
                <div class="section">
                    <p class="f-20 medium mb-3 white hide-underline">
                        {{ $t('contact-us') }}
                    </p>
                    <div class="d-flex justify-content-between">
                        <a class="entity" href="https://www.facebook.com/JumlaTech.Sy" target="_blank">
                            <img src="@/assets/img/facebook.svg" alt="">
                        </a>
                        <a class="entity" href="https://www.instagram.com/JumlaTech.Sy" target="_blank">
                            <img src="@/assets/img/instagram.svg" alt="">
                        </a>
                        <a class="entity" href="http://wa.me/+963950001340" target="_blank">
                            <img src="@/assets/img/whatsapp.svg" alt="">
                        </a>
                        <a class="entity" href="http://t.me/jumlatech" target="_blank">
                            <img src="@/assets/img/telegram.svg" alt="">
                        </a>
                        <a class="entity" href="tel:+963950001340" target="_blank">
                            <img src="@/assets/img/mobile.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <p class="text-center mb-4 pb-2 light white f-18 position-absolute l-0 r-0 b-0 ">
                {{ $t('rights') }}
            </p>
        </div>
    </footer>
    <footer class="container-fluid d-flex flex-column justify-content-between mt-11vh" id="footer" v-else>
        <div class="row">
            <div
                class="col-12 px-0 align-items-center h-fit-content d-flex justify-content-center flex-column px-0 mx-auto position-relative">
                <img class="footer-bg w-100" src="@/assets/img/mobile/footerbg-mobile-en.svg" alt="">
                <div class="position-absolute l-0 r-0 b-0 align-items-center justify-content-center d-flex">
                    <img class="w-100" src="@/assets/img/mobile/contact-mobile-en.svg" alt="">
                    <div class="text position-absolute d-flex flex-column justify-content-center t-0 b-0">
                        <p class="text-center mb-0 light white f-14 contact-us-title">
                            {{ $t('contact-us') }}
                        </p>
                        <div class="d-flex justify-content-between mt-2">
                            <a class="entity" href="https://www.facebook.com/JumlaTech.Sy" target="_blank">
                                <img src="@/assets/img/facebook.svg" alt="">
                            </a>
                            <a class="entity" href="https://www.instagram.com/JumlaTech.Sy" target="_blank">
                                <img src="@/assets/img/instagram.svg" alt="">
                            </a>
                            <a class="entity" href="http://wa.me/+963950001340" target="_blank">
                                <img src="@/assets/img/whatsapp.svg" alt="">
                            </a>
                            <a class="entity" href="http://t.me/jumlatech" target="_blank">
                                <img src="@/assets/img/telegram.svg" alt="">
                            </a>
                            <a class="entity" href="tel:+963950001340" target="_blank">
                                <img src="@/assets/img/mobile.svg" alt="">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="links position-absolute d-flex flex-column justify-content-start mt-4 t-0 align-items-center">

                    <router-link :to="{ name: 'about-app' }" class="f-20 regular white hide-underline mt-4 footer-link">
                        {{ $t('about-the-app') }}
                    </router-link>
                    <router-link :to="{ name: 'terms-and-conditions' }"
                        class="f-20 regular white hide-underline mt-2 mt-md-3 footer-link">
                        {{ $t('terms-and-conditions') }}
                    </router-link>
                    <router-link :to="{ name: 'privacy-policy' }"
                        class="f-20 regular white hide-underline mt-2 mt-md-3 footer-link">
                        {{ $t('privacy-policy') }}
                    </router-link>
                    <p class="text-center  mb-0 light white f-14  mt-3 rights " v-html="$t('rights-mobile')">

                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    computed: {
        lg() {
            return this.$store.getters.isLg
        },
        xl() {
            return this.$store.getters.isXl
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
    methods: {
        navigateTo(id) {
            if (this.currentRouteName == 'home' && document.getElementById(`${id}`))
                document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
            else {
                this.$router.push({ name: 'home' });
                setTimeout(() => {
                    document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
            // this.closeMenu();
        }
    }
}
</script>
<style scoped>
.arabic .ms-5 {
    margin-right: 3rem !important;
    margin-left: 0rem !important;
}

.w-85 {
    width: 85.88%
}

footer {
    height: 50vh;
    margin-top: 7.8vh;
}

.arabic .entity {
    margin-left: 10px;
}

.english .entity {
    margin-right: 10px;
}

.arabic .entity:last-child {
    margin-left: 0px;
}

.english .entity:last-child {
    margin-right: 0px;
}

.hide-underline {
    text-decoration: none;
}

.mt-11vh {
    margin-top: 11vh;
}

.border-color-blue {
    color: #274185;
    width: 95%;
    border-bottom: 2px solid;
    margin: auto;
}

@media (max-width: 991.98px) {
    .footer-bg {
        max-width: 100%;
    }

    p.rights {
        line-height: 1.8;
    }

    footer {
        height: fit-content;
        margin-top: 0px;
    }

    .entity img {
        width: 31px;
    }

    .arabic .entity {
        margin-left: 15px;
    }

    .english .entity {
        margin-right: 15px;
    }

    .mt-11vh {
        margin-top: 5.5vh;
    }

}

@media (min-width: 567.98px) and (max-width: 992px) {
    .entity img {
        width: 45px;
        margin-top: 10px;
    }

    .links {
        margin-top: 11vh !important;
    }
}</style>