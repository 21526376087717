<template>
    <section class="container-fluid position-relative" id="who-section">
        <div class="row position-relative mx-0 w-100 stop-resize">
            <div class="col-12 text-center mb-xxl-5 pb-xxl-5 mb-3 pb-3 mt-11vh">
                <Title :title="$t('who-we-are-title')" class="mt-0" />
                <p class="f-24 white text-center light" v-html="$t('who-we-are-desc')">
                </p>
            </div>
            <div class="col-12 px-0 mb-xxl-5 px-0 mb-5">
                <div class="w-100 d-flex flex-wrap justify-tablet-center align-items-center">
                    <div class="w-50 align-self-start" @click="getCardData(0)">
                        <picture>
                            <source media="(max-width:991.98px)" :srcset="require('@/assets/img/mobile/mmustafa.svg')">
                            <img :src="require(`@/assets/img/mustafa-${getCurrentLang}.svg`)"
                                class="w-100 align-self-start tablet-img">
                        </picture>
                        <div class="d-lg-none d-flex flex-column justify-content-center mt-3">
                            <p class="mb-0 f-16 white text-center bold">
                                {{ $t('mustafa') }}
                            </p>
                            <p class="mb-0 f-12 light white text-center">
                                {{ $t('ceo') }}
                            </p>
                            <p class="f-10 light white text-center">
                                {{ $t('more') }}...
                            </p>
                        </div>
                    </div>
                    <div class="w-50 mt-20 text-center align-self-start" @click="getCardData(3)">
                        <picture>
                            <source media="(max-width:991.98px)" :srcset="require('@/assets/img/mobile/manas.svg')">
                            <img :src="require(`@/assets/img/anas-${getCurrentLang}.svg`)" class="w-90 me-auto  tablet-img">
                        </picture>
                        <div class="d-lg-none d-flex flex-column justify-content-center mt-3">
                            <p class="mb-0 f-16 white text-center bold">
                                {{ $t('anas') }}
                            </p>
                            <p class="mb-0 f-12 light  white text-center">
                                {{ $t('coo') }}
                            </p>
                            <p class="f-10 light white text-center">
                                {{ $t('more') }}...
                            </p>
                        </div>
                    </div>
                    <div class="w-50 align-self-lg-start align-self-start mt-4 mt-lg-0" @click="getCardData(2)">
                        <picture>
                            <source media="(max-width:991.98px)" :srcset="require('@/assets/img/mobile/mnazir.svg')">
                            <img :src="require(`@/assets/img/nazir-${getCurrentLang}.svg`)"
                                class="w-100 align-self-start  tablet-img">
                        </picture>
                        <div class="d-lg-none d-flex flex-column justify-content-center mt-3">
                            <p class="mb-0 f-16 white text-center bold">
                                {{ $t('nazir') }}
                            </p>
                            <p class="mb-0 f-12 light white text-center">
                                {{ $t('cmo') }}
                            </p>
                            <p class="f-10 light white text-center">
                                {{ $t('more') }}...
                            </p>
                        </div>
                    </div>
                    <div class="w-50 text-center mt-20 align-self-start" @click="getCardData(1)">
                        <picture>
                            <source media="(max-width:991.98px)" :srcset="require('@/assets/img/mobile/mmouaid.svg')">
                            <img :src="require(`@/assets/img/mouaid-${getCurrentLang}.svg`)"
                                class="w-90 me-auto  tablet-img">
                        </picture>
                        <div class="d-lg-none d-flex flex-column justify-content-center mt-3">
                            <p class="mb-0 f-16 white text-center bold">
                                {{ $t('moaiad') }}
                            </p>
                            <p class="mb-0 f-12 light white text-center">
                                {{ $t('cto') }}
                            </p>
                            <p class="f-10 light white text-center">
                                {{ $t('more') }}...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ManagerCard v-if="!xl && !lg" @close="close" :name="this.cardDetails.name" :desc="this.cardDetails.desc"
            :position="this.cardDetails.position" :characterImg="this.cardDetails.img"
            :class="viewCard != 'init' ? (!viewCard ? 'close' : 'open') : 'default'" />
    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import ManagerCard from '../sections_subs/ManagerCard.vue';
export default {
    data() {
        return {
            cardDetails: {
                img: '',
                name: '',
                desc: '',
                position: '',
                view: 'init'
            },
            managers: [
                {
                    name: this.$root.$t('mustafa'),
                    position: this.$root.$t('ceo-title'),
                    desc: this.$root.$t('ceo-desc'),
                    img: 'mmustafa.svg'
                },
                {
                    name: this.$root.$t('moaiad'),
                    position: this.$root.$t('cto-title'),
                    desc: this.$root.$t('cto-desc'),
                    img: 'mmouaid.svg'
                },
                {
                    name: this.$root.$t('nazir'),
                    position: this.$root.$t('cmo-title'),
                    desc: this.$root.$t('cmo-desc'),
                    img: 'mnazir.svg'
                },
                {
                    name: this.$root.$t('anas'),
                    position: this.$root.$t('coo-title'),
                    desc: this.$root.$t('coo-desc'),
                    img: 'manas.svg'
                },

            ],


        }
    },
    components: { Title, ManagerCard },
    computed: {
        xl() {
            return this.$store.getters.isXl
        },
        lg() {
            return this.$store.getters.isLg
        },
        viewCard() {
            return this.cardDetails.view
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
    methods: {
        getCardData(id) {
            this.cardDetails = this.managers[id];
            this.cardDetails.view = true;
        },
        close() {
            this.cardDetails.view = false;
        }
    }

}
</script>
<style scoped>
.arabic .w-90 {
    width: 90%;
}

.english .w-90 {
    width: 100%;
}

.mt-20 {
    margin-top: 20.9vh
}

.mt-11vh {
    margin-top: 11vh;
}

@media (max-width: 991.98px) {
    .close {
        left: 100%;
        transition: all .5s ease-in;
    }

    .open {
        left: 0;
        transition: all .5s ease-in;
    }

    .default {
        left: 100%;

    }

    .mt-11vh {
        margin-top: 5.5vh!important;
    }

    .mt-20 {
        margin-top: 0rem !important;
    }

    .mt-20:last-child {
        margin-top: 1.5rem !important;
    }

    .arabic .w-90,
    .english .w-90 {
        width: 100%;
    }

}

@media (max-width: 390px) and (max-height:670px) {
    .mt-11vh {
        margin-top: 7vh
    }

}

@media (min-width: 567.98px) and (max-width: 992px) {
    .tablet-img {
        width: 34.8372vw !important
    }

    .w-50.align-self-start {
        width: 40% !important;
        align-self: center !important;
        justify-content: center;
        display: grid;
    }

    .justify-tablet-center {
        justify-content: center;
    }
    .mt-11vh {
        margin-top: 5.5vh!important;
    }

}</style>