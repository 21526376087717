
<template>
    <div class="container-fluid position-relative z-2" id="jobs">
        <div class="row">
            <div class="col-11 mx-auto">
                <h1 class="f-50 w-fit-content mx-auto pe-4 ps-3 bold text-center white mt-4   position-relative">
                    <img src="@/assets/img/left-jobs-title.svg" alt="" class="position-absolute t-0 t-sm-10 left-5">
                    <img src="@/assets/img/right-jobs-title.svg" alt="" class="position-absolute t-0 t-sm-10 right-5">
                    {{ $t('jobs-title') }}
                </h1>
            </div>
            <div class="col-xl-10 col-12 mx-auto jobs-container">
                <div class="w-100  d-flex justify-content-between flex-wrap jobs-list" v-if="jobs.length > 0">
                    <div class="w-33 mt-10" v-for="job in jobs" :key="job.id">
                        <JobsCard :job="job" />
                    </div>
                    <!-- {{ jobs }} -->
                </div>
                <Loader v-if="isLoader" />
            </div>
        </div>
    </div>
</template>
<script>
import JobsCard from '@/components/UI/JobsCard.vue';
import Loader from '@/components/UI/Loader.vue';
import ServiceFactory from '@/services/ServiceFactory';
const jobs = ServiceFactory.get('jobs');
export default {
    data() {
        return {
            jobs: [],
            active: false
        }
    },
    components: {
        JobsCard,
        Loader
    },
    mounted() {
        this.getJobs();
    },
    methods: {
        async getJobs() {
            this.active = true;
            let jobsList = await jobs.list();
            if (jobsList && jobsList.length > 0) {
                this.jobs = jobsList;
            }
            this.active = false
        }
    },
    computed: {
        isLoader() {
            return this.active
        }
    }
}
</script>
<style scoped>
.jobs-container {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-33 {
    width: 50%;
    text-align: center;
}

.mt-10 {
    margin-top: 2%;
    margin-bottom: 2%;
}

.arabic .left-5 {
    left: -5%;
}

.arabic .right-5 {
    right: -5%;
}

.english .left-5 {
    left: -6%;
}

.english .right-5 {
    right: 7%;
}

@media (max-width: 991.98px) {
    .english .t-sm-10 {
        top: 0px !important;
        width: 3.5vw;
    }

    .w-33 {
        width: 100%;
    }

    .mt-10:first-child {
        margin-top: 7%;
        margin-bottom: 4%;
    }

    .mt-10 {
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .arabic .t-sm-10 {
        top: -4px !important;
        width: 2.62vw;
    }
}

@media (max-width: 567.98px) {}</style>