<template>
    <div class="container-fluid position-relative z-2" id="about-the-app">
        <div class="row">
            <div class="col-xl-12 col-12 px-lg-0 pt-xl-5 pt-0">
                <div class="w-90 px-80px d-flex">
                    <SideBarLinks />
                    <div class="content mx-auto" v-html="$t('about-the-app-content')">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import SideBarLinks from '@/components/UI/SideBarLinks.vue'
export default {
    components: {
        SideBarLinks
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
<style scoped>
.w-90 {
    width: 90%;
}

@media (max-width: 991.98px) {
    .w-90 {
        width: 100%;
    }

}</style>