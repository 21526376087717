<template>
    <section class="container-fluid position-relative" id="hero-section">
        <div class="row hero" v-if="xl||lg">
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow">
                <picture>
                    <source media="(max-width:991.98px)" srcset="@/assets/img/mobile/shadow.svg">
                    <img src="@/assets/img/shadow.svg" class="px-80px w-100" alt="">
                </picture>
            </div>
            <div class="col-6">
                <div class="pr-80px mt-155px">
                    <h1 class="bold f-100 white">
                        {{ $t('hero-title') }}
                    </h1>
                    <h2 class="f-45 medium white" v-html="$t('hero-desc')">
                    </h2>
                </div>
            </div>
            <div class="col-6">
                <div id="hero-section-img"></div>
                <img :src="require(`@/assets/img/hero-section-main-${getCurrentLang}.png`)" class="left-img">
            </div>
        </div>
        <div class="row hero" v-else>
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow">
                <picture>
                    <source media="(max-width:991.98px)" srcset="@/assets/img/mobile/shadow.svg">
                    <img src="@/assets/img/shadow.svg" class="px-80px w-100 d-none" alt="">
                </picture>
            </div>
            <div class="col-12 h-100 d-flex flex-column overflow-hidden position-relative">
                <div class="pr-80px my-auto d-flex flex-column justify-content-start hero-text  position-relative z-3">
                    <h1 class="bold f-80  white mb-0">
                        {{ $t('hero-title') }}
                    </h1>
                    <p class="f-40 medium white mb-0 width-75" v-html="$t('hero-desc')">
                    </p>
                    <ScrollSvg @click="navigateTo('about-section')" />
                </div>

                <picture>
                    <source media="(max-width:575.98px)"
                        :srcset="require(`@/assets/img/mobile/track-${getCurrentLang}.svg`)">
                    <img :src="require(`@/assets/img/tablet/track-${getCurrentLang}.svg`)"
                        class="position-absolute b-0 track" alt="">
                </picture>
                <picture>
                    <source media="(min-width:575.98px)" :srcset="require(`@/assets/img/tablet/sun-${getCurrentLang}.svg`)">
                    <img :src="require(`@/assets/img/mobile/sun-${getCurrentLang}.svg`)"
                        class="w-100 position-relative l-0 r-0 z-0 mt-auto sun" alt="">
                </picture>

                <div class="position-absolute hero-section-main l-0 r-0 b-0 t-0">
                    <picture>
                        <source media="(min-width:575.98px)"
                            :srcset="require(`@/assets/img/tablet/mobile-hero-section-sun-${getCurrentLang}.png`)">
                        <img :src="require(`@/assets/img/mobile/mobile-hero-section-sun-${getCurrentLang}.png`)"
                            id="hero-section-img-2" class="w-100 position-absolute  b-0 l-0 r-0  z-1" alt="">
                    </picture>
                    <picture>
                        <source media="(min-width:575.98px)"
                            :srcset="require(`@/assets/img/tablet/mobile-hero-section-night-${getCurrentLang}.png`)">
                        <img :src="require(`@/assets/img/mobile/mobile-hero-section-night-${getCurrentLang}.png`)"
                            class="w-100 position-absolute  b-0 l-0 r-0 z-2 hero-section-main-night" id="hero-section-img"
                            alt="">
                    </picture>
                </div>
            </div>

            <!-- <div class="col-12 px-0   position-relative">
                <img src="../../assets/img/mobile/sun.svg" class="position-absolute animate__slideInUp animate__animated animate__delay-2s  l-0   z-0  " alt="">

                <div class=" w-100 animate__slideInUp animate__animated ">
                    <img class="position-relative w-100 l-0 r-0"
                        :src="require(`@/assets/img/mobile/mobile-hero-section-night.svg`)" alt="jumlatech-hero-section">
                    <img class="position-absolute l-0 r-0 w-100"
                        :src="require(`@/assets/img/mobile/mobile-hero-section-sun.png`)" alt="jumlatech-hero-section">
                </div>



            </div> -->

        </div>
    </section>
</template>
<script>
import ScrollSvg from '@/components/svgs/ScrollSvg.vue'
export default {
    data() {
        return {
            src: 'mobile-hero-section-night.png'
        }
    },
    mounted() {
        document.querySelector('#hero-section .row.hero').style.height = (window.innerHeight - document.querySelector('.navbar').clientHeight) + 'px';
        if (!this.xl) {
            setTimeout(() => {
                if (document.getElementById('hero-section-img') != null && document.getElementById('hero-section-img-2') != null) {
                    document.getElementById('hero-section-img').classList.add('hero-section-img');
                    document.getElementById('hero-section-img').classList.remove('hero-section-main-night');
                    document.getElementById('hero-section-img').classList.remove('hero-section-main');
                    document.getElementById('hero-section-img-2').classList.remove('hero-section-main');
                }
            }, 4200);
        }

    },
    components: {
        ScrollSvg
    },
    computed: {
        xl() {
            return this.$store.getters.isXl;
        },
        lg() {
            return this.$store.getters.isLg;
        },
        getSrc() {
            return this.src
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
    methods: {
        navigateTo(id) {
            document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
        }
    }
}
</script>
<style scoped>
.scroll {
    width: 8.4vw;
    margin-top: 1.5vh;
}

#hero-section,
#mobile-hero-section {
    height: var(--hero-section);
}

.overflow-y-unset {
    overflow-y: unset;
}

.left-pattern {
    position: absolute;
    right: auto;
    left: 0;
    bottom: 0;
    z-index: 1;
    max-height: var(--hero-section) !important;
}

.right-pattern {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    z-index: 1;
    max-height: var(--hero-section) !important;
}

h1,
p {
    line-height: 1.5;
}

.aljumlatech {
    margin-bottom: 1.1vh;
    width: 31vw;
}

.left-img {
    bottom: 0;
    position: absolute;
    max-height: var(--hero-section) !important;
}

.arabic .left-img {
    left: 0;
}

.english .left-img {
    right: 0;
}

.mt-155px {
    margin-top: 10.1vh;
}

.sun {
    bottom: -100%;
    left: 0%;
}

.hero-section-main {
    /* bottom: -100%; */
    position: relative;
    max-width: 100%
}

.hero-section-main-night {
    /* opacity: 100%; */
    animation-name: goodMorning;
    animation-duration: .8s;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
}

.hero-section-img {
    animation-name: reverseGoodMorning;
    animation-duration: 4s;
    /* animation-fill-mode: forwards; */
    animation-iteration-count: infinite;
}

.track {
    width: 20vw;
    bottom: 0;
    z-index: 3;
}

.english .track {
    left: 100%;
    animation-name: mini-track-move-en;
    animation-duration: 1.5s;
    animation-delay: 2.7s;
    animation-fill-mode: forwards;
}

.arabic .track {
    right: 100%;
    animation-name: mini-track-move;
    animation-duration: 1.5s;
    animation-delay: 2.7s;
    animation-fill-mode: forwards;
}

.sun {
    animation-name: sun-rise;
    animation-duration: .8s;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
}

.hero-section-main {
    animation-name: slide-in-bottom;
    animation-duration: 2s;
    animation-delay: .2s;
    animation-fill-mode: forwards;

}

.hero-text {
    position: relative;
    animation-name: slide-in-right;
    animation-duration: 2s;
    animation-delay: .2s;
    animation-fill-mode: forwards;
}

.hero-img {
    position: relative;
    animation-name: slide-in-bottom;
    animation-duration: 2s;
    animation-delay: .2s;
    animation-fill-mode: forwards;
}

@keyframes slide-in-right {
    0% {
        right: -100%
    }

    50% {
        right: 10px
    }

    100% {
        right: 0
    }
}

@keyframes slide-in-bottom {
    0% {
        bottom: -100%;
    }


    50% {
        bottom: 10%;
    }

    100% {
        bottom: 0%
    }
}

@keyframes reverseGoodMorning {
    0% {
        opacity: 0%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

@keyframes goodMorning {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

@keyframes sun-rise {
    0% {
        bottom: -100%;
    }

    50% {
        bottom: 5%;
    }

    100% {
        bottom: 0%;
    }
}

@keyframes mini-track-move {
    0% {
        right: 100%;
    }

    100% {
        right: 0%;
    }
}

@keyframes mini-track-move-en {
    0% {
        left: 100%;
    }

    100% {
        left: 0%;
    }
}

@media (max-width: 992px) {}

@media (min-width: 575.98px) and  (max-width: 992px) {
    .track {
        width: 14.01vw;
    }

    @keyframes mini-track-move {
        0% {
            right: 100%;
        }

        100% {
            right: 18%;
        }
    }

    @keyframes mini-track-move-en {
        0% {
            left: 100%;
        }

        100% {
            left: 18%;
        }
    }

    .english .track {
        left: 100%;
    }

    .arabic .track {
        right: 100%;
    }

    .width-75 {
        width: 75%;
    }
}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    img {
        max-height: 100%;
    }

    /* .h-30vh {
        height: 30.7%;
    } */

    h1 {
        font-size: 35px !important;
    }

    #hero-section,
    #mobile-hero-section {
        height: var(--hero-section-mobile);
    }

    .left-pattern {
        max-height: var(--hero-section-mobile) !important;
        bottom: 0;
        z-index: 0;
    }

    .right-pattern {
        max-height: var(--hero-section-mobile) !important;
        top: auto;
        bottom: 0;
    }

    /* .hero-img {
        height: 51.4%
    } */



}
</style>