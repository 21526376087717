<template>
    <section class="container-fluid position-relative overflow-hidden" id="about-section">
        <div class="row w-100 mx-0">
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow">
                <img class="px-80px d-none d-lg-flex w-100" :src="require('@/assets/img/shadow.svg')" />
            </div>
            <div class="col-lg-6 col-12 px-0">
                <div class="px-80px p-end-lg-0">
                    <Title :title="$t('what-is-jumlatech')" />
                    <p class="f-40 mb-0 white light sub-title">
                        {{ $t('what-is-jumlatech-desc') }}
                    </p>
                    <swiper @slideChange="changeImg"
                    :autoplay="{
                        delay: 1800,
                        disableOnInteraction: false,
                    }" 
                     :speed="1500" :loop="true"  :pagination="true" :modules="modules" class="mySwiper w-100 mt-12vh">
                        <swiper-slide>
                            <div class="position-relative w-fit-content">
                                <h6 class="light-blue f-35 bold w-fit-content">
                                    {{ $t('about-step1') }}
                                </h6>
                                <img loading="lazy" src="@/assets/img/mobile/title-deco.svg" class="position-absolute t-0 r-100 flip-en" alt="">
                            </div>
                            <p class="mb-0 white light f-30" v-html="$t('about-step1-desc')">
                            </p>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="position-relative w-fit-content">
                                <h6 class="light-blue f-35 bold w-fit-content">
                                    {{ $t('about-step2') }}
                                </h6>
                                <img loading="lazy" src="@/assets/img/mobile/title-deco.svg" class="position-absolute t-0 r-100 flip-en" alt="">
                            </div>
                            <p class="mb-0 white light f-30"  v-html="$t('about-step2-desc')">
                            </p>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="position-relative w-fit-content">
                                <h6 class="light-blue f-35 bold w-fit-content">
                                    {{ $t('about-step3') }}
                                </h6>
                                <img loading="lazy" src="@/assets/img/mobile/title-deco.svg" class="position-absolute t-0 r-100 flip-en" alt="">
                            </div>
                            <p class="mb-0 white light f-30" v-html="$t('about-step3-desc')">
                            </p>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="position-relative w-fit-content">
                                <h6 class="light-blue f-35 bold w-fit-content">
                                    {{ $t('about-step4') }}
                                </h6>
                                <img loading="lazy" src="@/assets/img/mobile/title-deco.svg" class="position-absolute t-0 r-100 flip-en" alt="">
                            </div>
                            <p class="mb-0 white light f-30" v-html="$t('about-step4-desc')">
                            </p>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="position-relative w-fit-content">
                                <h6 class="light-blue f-35 bold w-fit-content">
                                    {{ $t('about-step5') }}
                                </h6>
                                <img loading="lazy" src="@/assets/img/mobile/title-deco.svg" class="position-absolute t-0 r-100 flip-en" alt="">
                            </div>
                            <p class="mb-0 white light f-30" v-html="$t('about-step5-desc')">
                            </p>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="col-lg-6 col-12 text-center" :class="{ 'h-100': (lg || xl) }">
                <ImgSlider />
            </div>
        </div>
    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import ImgSlider from '@/components/sections_subs/ImgSlider.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

export default {
    mounted() {
        // document.querySelector('#about-section .row').style.height = window.innerHeight + 'px';
    },
    components: {
        Swiper,
        SwiperSlide,
        Title,
        ImgSlider
    },
    setup() {
        return {
            modules: [Autoplay, Pagination],
        };
    },
    computed: {
        xl() {
            return this.$store.getters.isXl;
        },
        lg() {
            return this.$store.getters.isLg;
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
    methods: {
        changeImg(e) {
            this.animate(e.realIndex);
        },
        animate(active) {
            var here = this;
            var list = document.querySelectorAll('.slider-img');
            [].forEach.call(list, function (el) {
                if (here.getCurrentLang == 'ar')
                    el.classList.remove('animate__animated', 'animate__fadeInLeft', 'animate__fadeOut')
                else
                    el.classList.remove('animate__animated', 'animate__fadeInRight', 'animate__fadeOut')

            });
            if (active == 0) {
                list[4].classList.add('animate__animated', 'animate__fadeOut');
                if (here.getCurrentLang == 'ar')
                    list[0].classList.add('animate__animated', 'animate__fadeInLeft');
                else
                    list[0].classList.add('animate__animated', 'animate__fadeInRight');
            }
            else {
                if (here.getCurrentLang == 'ar')
                    list[active].classList.add('animate__animated', 'animate__fadeInLeft');
                else
                    list[active].classList.add('animate__animated', 'animate__fadeInRight');

                list[(active - 1)].classList.add('animate__animated', 'animate__fadeOut');
            }

        }
    }
}
</script>
<style scoped>
/* #about-section {
    height: 100vh
} */


/*     margin-top: 15.7%;
 */
 /* .english p ,.english h2 ,.english .text-center{
    text-align: left!important;
} */

.arabic .mt-12vh {
    padding-top: 12.8vh;
}
.english .mt-12vh{
    padding-top: 9.3vh;
}

.arabic .mySwiper {
    padding-bottom: 7.2vh;
    direction: rtl;
}
.english .mySwiper{
    padding-bottom: 6.2vh;
    direction: ltr;
}
.max-w-100vw {
    max-width: 100vw;
}

.max-h-100 {
    max-height: 100%;
}

.english .r-100 {
    left: 100%
}
.arabic .r-100 {
    right: 100%
}
.english .flip-en{
    transform: scaleX(-1)
}
@media (min-width: 992px) {
    .arabic .pe-lg-0 {
        padding-left: 0 !important;
    }
}

@media (max-width: 992px) {
    .english .mt-12vh {
    padding-top: 4.3vh;
}
    .slider-img {
        max-height: 35vh;
        margin-left: auto;
        margin-right: auto;
    }

    .mt-12vh {
        padding-top: 7vh!important;
    }

    /* .mt-11vh {
        padding-top: 4.5vh
    } */

    .w-68 {
        width: 94.8%;
    }
}

@media (max-width: 567.98px) {
    .mt-12vh {
        padding-top: 3vh!important;
    }
    .english .mySwiper{
    padding-bottom: 3.5vh;
}
}

@media (max-width: 390px) and (max-height:670px) {
    .mySwiper {
        padding-bottom: 3.5vh
    }


    .w-68 {
        width: 94.8%;
    }
}
@media (min-width: 1000px) and (max-width: 1200px) {
    .english .mt-12vh {
    padding-top: 5.3vh;
}
}
@media (min-width: 1200px){
    .arabic .p-end-lg-0{
        padding-left: 0;
    }
    .english .p-end-lg-0{
        padding-right: 0;
    }
}
</style>