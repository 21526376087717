import RepositoryFactory from '@/repositories/RepositoryFactory'

export default class PartnersService {
  constructor () {
    this.partners = RepositoryFactory.get('partners')
  }

  async list () {
    return this.partners
      .list()
      .then(response => {
        let partners = response.data.data
        return Promise.resolve(partners)
      })
      .catch(error => {
        Promise.resolve(null)
      })
      .finally(() => {})
  }
}
