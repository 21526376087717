import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import '@/assets/css/fonts.css'
import '@/assets/css/colors.css'
import '@/assets/css/global.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

createApp(App).use(store).use(VueSweetalert2).use(router).use(i18n).mount('#app')
