<template>
    <div id="job-apply" class="container-fluid position-relative z-2">
        <div class="row">
            <div class="col-12 px-0">
                <div class="px-80px mt-xxl-5 mt-4">
                    <div class="row mx-0 w-100">
                        <div class="col-xl-8 col-12 px-0">
                            <h1 class="f-40 bold orange border-bottom-1px w-fit-content mb-4">
                                {{ $t('apply-to-job-template') }}
                            </h1>
                            <ApplyForm />
                        </div>
                        <div class="col-xl-4 col-12 d-xl-flex d-none  h-fit-content px-0  aling-items-start">
                            <img src="@/assets/img/apply-form.svg" class="w-max-100" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApplyForm from '@/components/sections_subs/ApplyForm.vue';
export default {
    components: {
        ApplyForm
    }
}
</script>
<style scoped>
.row{
    align-items: flex-start;
}
.english .w-max-100{
    max-width:90%;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: auto;
}
.arabic .w-max-100{
    max-width:90%;
    margin-right: auto;
    margin-bottom: auto;
}
.orange {
    color: #F26B10;
}

.border-bottom-1px {
    border-bottom: 1px solid #F26B10;
    padding-bottom: 10px;
}
</style>