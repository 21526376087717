
<template>
    <div id="job-details" class="container-fluid position-relative z-2">
        <div class="row">
            <div class="col-12 px-0">
                <div class="px-80px mt-xxl-5 mt-4" >
                    <h1 class="f-40 bold orange border-bottom-1px w-fit-content">
                        {{ job.name }}
                    </h1>
                    <div class="row mx-0 w-100">
                        <div class="col-lg-5 col-12 px-0">
                            <p class="f-24 semibold white mt-xxl-4 mt-2 pt-2 mb-0 ">
                                {{ $t('opportunity-details') }}
                            </p>
                            <div class="d-flex flex-lg-row flex-column-reverse justify-content-between">

                                <div class="d-flex flex-column">

                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('gender') }} : <spna class="light">{{ job.gender == 'male' ?
                                            $t('male') : (job.gender == 'female' ? $t('female') : $t('not-specefied')) }} </spna>
                                    </p>
                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('age') }} : <spna class="light">{{ job.age }} </spna>
                                    </p>

                                    <!-- <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        التعليم : <spna class="light">غير محدد </spna>
                                    </p> -->
                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('experience') }} : <spna class="light"> {{ job.experience }} </spna>
                                    </p>
                                   
                                </div>
                                <div class="d-flex flex-column">
                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('employement-level') }} : <spna class="light">{{ job.department }} </spna>
                                    </p>
                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('job-type') }} : <spna class="light"> {{ job.job_type == 'full' ?
                                            $t('fulltime') : $t('parttime') }}</spna>
                                    </p>
                                    <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        {{ $t('langs') }} : <spna class="light">{{ job.lan == '' ? $t('ar') : $t('en') }}
                                        </spna>
                                    </p>
                                    <!-- <p class="f-22 semibold white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0">
                                        الراتب : <spna class="light">غير محدد </spna>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 offset-lg-1 px-0 px-lg-2 mt-md-3 mt-lg-0">
                            <p class="f-24 px-lg-2 semibold white mt-xxl-4 mt-2 pt-2 mb-0">
                                {{ $t('job-description') }}
                            </p>
                            <p class="f-22 px-lg-2 light white mt-xxl-3 mt-2 pt-xxl-2 pt-1 mb-0 job-description" v-html="job.description">
                            </p>
                        </div>
                        <div class="col-12 px-0  mt-md-3 mt-lg-0">
                            <p class="f-24 semibold white mt-lg-4 pt-lg-3 mb-0">
                                {{ $t('opportunity-requirements') }}
                            </p>
                            <p class="f-22 light white mt-2 mb-0 qualifications" v-html="job.qualifications">
                            </p>
                        </div>
                        <div class="col-12 px-0">
                            <div class="d-flex mt-lg-0 mt-4 justify-content-end mx-lg-5">
                                <router-link :to="{ name: 'apply', params: { id: '1' } }">
                                    <div
                                        class="w-fit-content position-relative d-flex align-items-center justify-content-center">
                                        <img src="@/assets/img/apply.svg" class="apply" alt="">
                                        <p class="f-20 white position-absolute mb-0 text-center  semibold">
                                            {{ $t('apply') }}
                                        </p>
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'jobs' }">
                                    <div
                                        class="mx-lg-4 m-end-4 w-fit-content position-relative d-flex align-items-center justify-content-center">
                                        <img src="@/assets/img/apply.svg" class="apply" alt="">
                                        <p class="f-20 white position-absolute mb-0 text-center semibold">
                                            {{ $t('back') }}
                                        </p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ServiceFactory from '@/services/ServiceFactory';
const jobs = ServiceFactory.get('jobs')
export default {
    data() {
        return {
            job: {}
        }
    },
    mounted() {
        this.getJob('1')
    },
    methods: {
        async getJob(id) {
            var res = await jobs.get(id);
            if (res!=undefined && res.name) {
                this.job = res
            }
        }
    }
}
</script>
<style scoped>
.orange {
    color: #F26B10;
}

.border-bottom-1px {
    border-bottom: 1px solid #F26B10;
    padding-bottom: 10px;
}

.arabic .offset-1 {
    margin-right: 8.33333333%;
    margin-left: 0%;

}

.apply {
    width: 8.3vw;
}

@media (max-width: 1199.98px) {
    .apply {
    width: 30.3vw;
} 
.english .m-end-4{
    margin-left: 1.5rem;
}
.arabic .m-end-4{
    margin-right: 1.5rem;
}
}
@media (max-width: 1199.98px) {
    .apply {
    width: 18vw;
} 
}
</style>