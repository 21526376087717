import RepositoryFactory from '@/repositories/RepositoryFactory'
import job from '@/models/job'

export default class JobsService {
  constructor () {
    this.jobs = RepositoryFactory.get('jobs')
  }

  async list () {
    return this.jobs
      .list()
      .then(response => {
        var jobs = []
        response.data.data.forEach(function (j) {
          var n = new job(j)
          jobs.push(n)
        })
        return Promise.resolve(jobs)
      })
      .catch(error => {
        Promise.resolve(null)
        var array =  [{"id": 1, "name": "Client Relations Managers", "department": "Sales", "state_id": " \u0631\u064a\u0641 \u062f\u0645\u0634\u0642", "experience": false, "create_date": "2022-06-02", "job_type": "full"}, {"id": 7, "name": "Deivery Driver", "department": "Accounting", "state_id": " \u0631\u064a\u0641 \u062f\u0645\u0634\u0642", "experience": false, "create_date": "2022-07-28", "job_type": "full"}, {"id": 2, "name": "General Manager", "department": null, "state_id": " \u0631\u064a\u0641 \u062f\u0645\u0634\u0642", "experience": false, "create_date": "2022-06-28", "job_type": "full"}];
        var jobs = [];
        array.forEach(function (j) {
          var n = new job(j)
          jobs.push(n)
        })
        return Promise.resolve(jobs)
      })
      .finally(() => {})
  }

  async get (id) {
    return this.jobs
      .get(id)
      .then(response => {
        let j = new job(response.data.data)
        return Promise.resolve(j)
      })
      .catch(error => {
        return Promise.resolve(null)
      })
      .finally(() => {})
  }

  async apply (credential) {
    return this.jobs
    .apply(credential)
      .then(response => {
        return Promise.resolve(response.data.success)
      })
      .catch(error => {
        return Promise.resolve(false)
      })
      .finally(() => {})
  }
}
