<template>
  <div class="position-relative z-2" id="home" >
    <HeroSection />
    <AboutSection />
    <DownloadSection />
    <HowSection />
    <FeedBacks />
    <PartnersSection :fetch="fetch"/>
    <WhoWeAre />
    <ContactSection />
  </div>
  {{ show }}
  <FullLoader v-if="show"/>
</template>
<script>
// @ is an alias to /src
import AboutSection from '@/components/sections/AboutSection.vue';
import HeroSection from '../components/sections/HeroSection.vue'
import DownloadSection from '../components/sections/DownloadSection.vue'
import PartnersSection from '@/components/sections/PartnersSection.vue'
import ContactSection from '@/components/sections/ContactSection.vue'
import WhoWeAre from '@/components/sections/WhoWeAre.vue';
import HowSection from '@/components/sections/HowSection.vue';
import FeedBacks from '@/components/sections/FeedBacks.vue';
import FullLoader from '@/components/UI/FullLoader.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      prev_id: '',
      show:true ,
      fetch:false
    }
  },
  components: {
    HeroSection,
    AboutSection,
    DownloadSection,
    PartnersSection,
    ContactSection,
    WhoWeAre,
    HowSection,
    FeedBacks,
    FullLoader
  },
  created() {
    console.log('load')
    window.addEventListener("scroll", this.onScroll);
    if (document.readyState === 'complete') {
        this.changeShow(); // Run the logic directly if already loaded
    } else {
        window.addEventListener("load", this.changeShow); // Otherwise, listen for the load event
    }
    // window.addEventListener("load", this.changeShow);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  mounted() {
    this.setScreenBreakpoints();
  },
  beforeUnmount() {
    console.log('beforeUnmount')
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("load", this.changeShow);
  },

  computed: {
    xl() {
      return this.$store.getters.isXl
    },
    lg() {
      return this.$store.getters.isLg
    }
  },
  methods: {
    onResize(e) {
      this.setScreenBreakpoints();
      // this.setSectionsHeight();
    },
    changeShow(){
      this.show = false
      this.fetch = true
    },
    onScroll(e) {
      const currentRegion = [...document.querySelectorAll("section"), document.querySelector("footer")]
        .find(e => e.getBoundingClientRect().top >= -30);
      if (this.prev_id == '' && currentRegion)
        this.prev_id = currentRegion.id
      if (this.lg || this.xl) {
        if (document.getElementById("how-section").getBoundingClientRect().top < 10) {
          document.getElementById('how-road-track').classList.add('animate-the-track');
          setTimeout(() => {
            if (this.$route.name == 'home' && (this.lg || this.xl))
              document.querySelector('.step-1.road-step').classList.add('animate__animated', 'animate__fadeInUp')
          }, 1000)
          setTimeout(() => {
            if (this.$route.name == 'home' && (this.lg || this.xl))
              document.querySelector('.step-2.road-step').classList.add('animate__animated', 'animate__fadeInUp')
          }, 3000)
          setTimeout(() => {
            if (this.$route.name == 'home' && (this.lg || this.xl))
              document.querySelector('.step-3.road-step').classList.add('animate__animated', 'animate__fadeInUp')
          }, 5000)
        }
        if (currentRegion && currentRegion.id == 'hero-section' && currentRegion.getBoundingClientRect().top < document.querySelector('.navbar').clientHeight - 1) {
          document.querySelector(`#${currentRegion.id} .row .shadow`).classList.add('invisible-shadow');
        }
        else if (currentRegion && currentRegion.id == 'hero-section' && currentRegion.getBoundingClientRect().top >= document.querySelector('.navbar').clientHeight - 1)
          document.querySelector(`#${currentRegion.id} .row .shadow`).classList.remove('invisible-shadow');

        if (currentRegion && currentRegion.id != this.prev_id) {
          const boxes = document.querySelectorAll('section .row .shadow');
          for (const box of boxes) {
            box.classList.add('invisible-shadow');
          }
          if (currentRegion != 'footer' && document.querySelector(`#${currentRegion.id} .row .shadow`))
            document.querySelector(`#${currentRegion.id} .row .shadow`).classList.remove('invisible-shadow');
          this.prev_id = currentRegion.id
        }
      }
    },
    setScreenBreakpoints() {
      this.screen = window.innerWidth;
      this.$store.dispatch('setSm', this.screen >= 576 && this.screen < 768)
      this.$store.dispatch('setMd', this.screen >= 768 && this.screen < 992)
      this.$store.dispatch('setLg', this.screen >= 992 && this.screen < 1200)
      this.$store.dispatch('setXl', this.screen >= 1200)
    },
    setSectionsHeight() {
      var sections = document.querySelectorAll('section div.row');
      var screen = window.innerHeight;
      for (var section of sections) {
        if (!section.classList.contains('hero')) {
          if (!section.classList.contains('stop-resize'))
            section.style.height = screen + 'px';
        }
        else
          section.style.height = (screen - document.querySelector('.navbar').clientHeight) + 'px';
      }
    }
  }
}
</script>

