export default class job{
    constructor(job){
    this.id = job.id ,
    this.name = job.name , 
    this.gender = job.gender ?? 'male', // male , female ,others
    this.department = job.department ?? '',
    this.state = job.state_id ?? '',
    this.address = job.address_id ?? '',
    this.experience = job.experience ?? '0',
    this.job_type = job.job_type ?? 'full', //part , full
    this.lan = job.lan ?? 'ar', 
    this.age = job.age ?? '0',
    this.create_date = job.create_date ?? '',
    this.description = job.description ?? '',
    this.qualifications = job.qualifications ?? ''

    }
}