<template>
    <div class="job  mx-auto w-fit-content ">
        <img src="@/assets/img/anchor.svg" class="position-relative" alt="">

        <div class="position-relative ">
            <router-link :to="{ name: 'job', params: { id: job.id } }">
                <img src="@/assets/img/job-card.svg" class="w-21vw" alt="">
                <div class="text position-absolute d-flex flex-column justify-content-between t-0 b-0 l-0 r-0 padding-12">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="f-24 bold white mb-0 text-start name" data-bs-toggle="tooltip" data-bs-placement="top"
                            :title="job.name">
                            {{ job.name }}
                        </p>
                        <div class="d-flex justify-content-start">
                            <img src="@/assets/img/calander.svg" class="px-1" alt="">
                            <p class="f-20 light white mb-0 ">
                                {{ job.create_date }}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-start mb-2" v-if="job.department != ''">
                                <img src="@/assets/img/position.svg" alt="">
                                <p class="f-22 light white mb-0 px-2">
                                    {{ job.department }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-start mb-2">
                                <img src="@/assets/img/time.svg" alt="">
                                <p class="f-22 light white mb-0 px-2 " v-if="job.job_type = 'full'">
                                    {{ $t('fulltime') }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex justify-content-start mb-2">
                                <img src="@/assets/img/location.svg" alt="">
                                <p class="f-22 light white mb-0 px-2">
                                    {{ job.state }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-start mb-2">
                                <img src="@/assets/img/experience.svg" alt="">
                                <p class="f-22 light white mb-0 px-2">
                                    {{ job.experience }}
                                    <!--  v-if="job.experience != '1' && job.experience != '0'" {{ $t('years') }} -->
                                </p>
                                <!-- <p class="f-22 light white mb-0 px-2" v-else-if="job.experience == '1'">
                    {{ $t('one-year') }}
                </p>
                <p class="f-22 light white mb-0 px-2" v-else-if="job.experience == '0'">
                    {{ $t('experience-is-not-required') }}
                </p> -->
                            </div>
                        </div>
                    </div>


                    <router-link :to="{ name: 'apply', params: { id: job.id } }">
                        <div
                            class="w-fit-content position-relative d-flex align-items-center justify-content-center ms-xxl-auto">
                            <picture>
                                <source media="(max-width:991.98px)" srcset="@/assets/img/mobile/apply.svg">

                                <img src="@/assets/img/apply.svg" class="apply" alt="">

                            </picture>
                            <p class="f-20 white position-absolute mb-0 text-center  semibold">
                                {{ $t('apply') }}
                            </p>
                        </div>
                    </router-link>
                </div>
            </router-link>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        job: Object
    }
}
</script>
<style scoped>
.arabic .ms-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.english .ms-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.w-21vw {
    width: 34.16vw
}

.b-98 {
    bottom: 98%;
}

.padding-12 {
    padding-left: 12.8%;
    padding-right: 12.8%;
    padding-top: 14%;
    padding-bottom: 6%;
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.apply {
    width: 8.3vw;
}

@media (max-width: 1699.98px) {
    .padding-12 {
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 9%;
        padding-bottom: 4%;
    }
}

/* tablet: 87.17vw | mobile : 79.9vw */
@media (max-width: 991.98px) {
    .w-21vw {
        width: 80vw
    }

    .apply {
        width: 20vw;
    }

    .ms-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

@media (max-width: 567.98px) {
    .apply {
        width: 26.9vw;
    }

    .w-21vw {
        width: 91.9vw;
    }

    .padding-12 {
        padding-left: 6%;
        padding-right: 6%;
        padding-top: 7%;
        padding-bottom: 4%;
    }
}
</style>