<template>
  <svg class="scroll" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.035 44.152">
    <g id="Group_21919" data-name="Group 21919" transform="translate(-315.863 -383.836)">
      <g id="Group_21354" data-name="Group 21354" transform="matrix(-0.017, 1, -1, -0.017, 348.899, 384.404)">
        <g id="Group_371" data-name="Group 371" transform="translate(0 0)">
          <g id="Group_369" data-name="Group 369">
            <path id="Path_281" data-name="Path 281" d="M0,0,27.441,1.373,27.73,29.2,2.03,32.556Z" fill="#ed6b14" />
            <path id="Path_282" data-name="Path 282" d="M0,.382,23.563,0l-.055,25.253L1.1,27.644Z"
              transform="translate(2.811 2.263)" fill="#131631" />
            <path id="Path_283" data-name="Path 283" d="M1.148,25.4l19.464-2.456L20.867,0,0,1.543Z"
              transform="translate(4.696 3.069)" fill="#0e1141" />
          </g>
        </g>
      </g>
      <path id="Subtraction_2" data-name="Subtraction 2" d="M4.775,0,4.448,5.856.38,5.8,0,.366Z"
        transform="translate(331.088 391.126)" fill="#ed6b14" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
        stroke-width="1" />
      <g id="Group_21353" data-name="Group 21353" transform="translate(321.517 416.926)">
        <path id="Path_20310" data-name="Path 20310"
          d="M21591.748,516.7l-1.184-.874-10.73-8.792,2.484-1.4,9.2,9.224,10.07-9.224,2.16,1.4Z"
          transform="translate(-21579.834 -505.637)" fill="#ed6b14" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  methods: {
    navigateTo(id) {
      document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>
<style scoped>
.scroll {
  opacity: 0%;

  margin-top: 2vh;
  animation-duration: 1.5s;
  animation-delay: 2.7s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes changePosition {
  0% {
    transform: translate(331.088px, 391.126px);
  }

  100% {
    transform: translate(331.088px, 402.126px);
  }
}

#Subtraction_2 {
  animation-delay: 4.2s;
  animation-duration: 1s;
  animation-name: changePosition;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

@media (min-width: 567.98px) and (max-width: 992px) {
  .scroll {
    width: 5vw!important;
  }
}
</style>