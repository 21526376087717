import JobsService from './JobsServices'
import PartnersService from './PartnersService'

const services = {
  partners: new PartnersService(),
  jobs : new JobsService()
}

export default {
  get: service => services[service]
}
