import BaseRepository from '@/repositories/Repository'

export default class JobsRepository extends BaseRepository {

  async list () {
    return this.http.get(`/jobs`)
  }

  async get (id) {
    return this.http.get(`/jobs/${id}`)
  }

  async apply (credential) {
    return this.http.post(`/jobs/apply`,credential , { headers: {
      "Content-Type": "multipart/form-data"
    }})
  }
  
}
