<template>
    <div class="position-fixed t-0  w-100vw menu-bg z-4 menu b-0"  :class="getCurrentLang == 'en' ? 'english' : 'arabic'">
        <ul class="navbar-nav px-0 mx-auto nav-bg position-relative">
                <li class="nav-item">
                    <a class="nav-link active semibold f-20"   type="button" @click="navigateTo('about-section')">{{ $t('about-jumlatech') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link semibold f-20" type="button" @click="navigateTo('how-section')">{{ $t('how-to-work') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link  semibold f-20" type="button" @click="navigateTo('partners-section')">{{ $t('our-partners') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link semibold f-20" type="button" @click="navigateTo('who-section')">{{ $t('who-are-we') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link semibold f-20" type="button" @click="navigateTo('contact-section')">{{ $t('contact-us') }}</a>
                </li>
                <li class="nav-item" @click="closeMenu">
                    <router-link class="nav-link semibold f-20" :to="{name:'jobs'}" >{{ $t('jobs-title') }}</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link semibold f-20 " type="button" @click="navigateTo('download-section')">{{ $t('download-the-app') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20 mb-0" type="button" @click="changeLang">{{ $t('lang') }}</a>
                </li>
                <img class="position-absolute b-0 menu-track flash" :src="require(`@/assets/img/mobile/menu-track-${getCurrentLang}.svg`)" alt="menu-track">
                <img class="position-absolute b-0 menu-track normal" :src="require(`@/assets/img/mobile/menu-track-normal-${getCurrentLang}.svg`)" alt="menu-track">

                <div class="position-fixed r-10 back d-flex justify-content-center align-items-center pointer" @click="closeMenu()">
                    <p class="white f-20-mobile light position-absolute mb-2">{{ $t('back') }}</p>
                <img src="../../assets/img/mobile/go-back.svg" alt="back" >
            </div>
            </ul>
    </div>
</template>
<script>
export default {

    methods:{
        closeMenu(){
            this.$store.dispatch('setMenu',false)
        },
        navigateTo(id){
            if (this.currentRouteName == 'home' && document.getElementById(`${id}`))
                document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
            else{
                this.$router.push({name:'home'});
                setTimeout(() => {
                    document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
            this.closeMenu();
        },
        changeLang() {
            if (this.$store.getters.getLocal == 'ar')
                this.$store.dispatch('setCurrentLocale', 'en')
            else
                this.$store.dispatch('setCurrentLocale', 'ar')
                window.location.reload();
        }
    },
    computed:{
        getCurrentLang(){
            return this.$store.getters.getLocal
        }
    }
}
</script>
<style scoped>
.close .flash{
    visibility: visible;
}
.close .normal{
    visibility: hidden;
}
.open .flash{
    visibility: hidden;
}
.open .normal{
    visibility: visible;
}
.back img{
    width: 27.17vw;
}
/* .h-100vh{
    height: 100vh;
} */
.w-100vw{
    width: 100vw;
}
.menu-bg{
    background-image:url('../../assets/img/mobile/menu-bg.svg');
     background-color: #0e1141;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.r-10{
    right: 6px;
}

.navbar-nav {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: auto!important;
    margin-right: auto!important;
}

.nav-item .nav-link,
.nav-item .nav-link.active {
    color: white;
    padding: 0 !important;
    margin-bottom:5.06vh;
}

.arabic.open.menu{
    left: -148.2vw;
}
.english.open.menu{
    right: -148.2vw;
}
.arabic.open.menu{
    animation-name: menu-move;
    animation-duration: 4s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}
.english.open.menu{
    animation-name: menu-move-en;
    animation-duration: 4s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}
.arabic.close.menu{
    animation-name: menu-close;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
.english.close.menu{
    animation-name: menu-close-en;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
.back{
    bottom: -11.26vh;
}
.open .back{
    animation-name: back;
    animation-duration: 1s;
    animation-delay: 3.4s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.close .back{
    animation-name: back-back;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    opacity: 100%;
}

.open .menu-track{
    z-index: 2;
    transition: all 1s ease-in-out;
} 
.arabic.open .menu-track{
    right: -46.2vw;
}
.english.open .menu-track{
    left: -46.2vw;
} 
.close .menu-track{
    z-index: 2;
    transition: all 1s ease-in-out;
}
.arabic.close .menu-track{
    right: 3vw;
}
.english.close .menu-track{
    left: 3vw;
}
@keyframes back {
    0% {
        bottom: -11.26vh;
        opacity: 0;
    }
    50% {
        bottom: -.5vh;
        opacity:100%;
    }
    100% {
        bottom: -1vh;
        opacity:100%;
    }
}

@keyframes back-back {
    0% {
        bottom: -1vh;
        opacity:100%;
    }
    
    50% {
        bottom: -.5vh;
        opacity:100%;
    }
    100% {
        bottom: -11.26vh;
        opacity: 0;
    }
}
@keyframes menu-move {
    0% {
        left: -148.2vw;
    }
    50% {
        left: 10px;
    }
    100% {
        left: 0;
    }
}
@keyframes menu-move-en {
    0% {
        right: -148.2vw;
    }
    50% {
        right: 10px;
    }
    100% {
        right: 0;
    }
}
@keyframes menu-close {
    0% {      
          left: 0;

    }
    100% {
        left: -148.2vw;

    }
}
@keyframes menu-close-en {
    0% {      
          right: 0;

    }
    100% {
        right: -148.2vw;

    }
}
@media (min-width: 575.98px) and  (max-width: 992px) {
    .menu-bg{
    background-image:url('@/assets/img/tablet/menu-bg.svg');
}
.arabic.close .menu-track{
    right: 3vw;
}
.english.close .menu-track{
    left: 3vw;
}
.arabic.open .menu-track{
    right: -25.2vw;
}
.english.open .menu-track{
    left: -25.2vw;
} 
}

</style>