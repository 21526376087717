import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutApp from '../views/AboutApp.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import Jobs from '../views/Jobs.vue'
import JobDetails from '../views/JobDetails.vue'
import Apply from '../views/Apply.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-app',
    name: 'about-app',
    component: AboutApp
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
    },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions
  }, 
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/job/:id',
    name: 'job',
    component: JobDetails
  },
  {
    path: '/job/:id/apply',
    name: 'apply',
    component: Apply
  },
]

const router = createRouter({
  // history:  createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'hash'

  // linkActiveClass: "active-link",
  // linkExactActiveClass: "active-link"
})

export default router
