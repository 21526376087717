import cookiesHelper from "@/helpers/CookiesHelper";

const LOCALE_KEY = 'locale';


export const changeLocale = (localeCode) => {
    cookiesHelper.set(LOCALE_KEY, localeCode);
};

export const getCurrentLocale = () => {
    const locale = cookiesHelper.get(LOCALE_KEY);
    return locale ? locale : 'en';
};

export default {
    getCurrentLocale,
    changeLocale
};