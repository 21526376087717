import JobsRepository from "./JobsRepository";
import PartnersRepository from "./PartnersRepository";


const repos = {
    partners: new PartnersRepository(),
    jobs : new JobsRepository()
};

export default {
    get: (repo) => repos[repo],
};