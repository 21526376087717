<template>
    <nav class="navbar navbar-expand-lg py-0 container-fluid">
        <div class="row w-100 mx-0">
            <div class="col-12 px-0">
            
                <picture>
                    <source media="(max-width:575px)"
                    :srcset="require(`@/assets/img/mobile/nav-${getCurrentLang}.svg`)" >
                    <source media="(max-width:992px)"
                    :srcset="require(`@/assets/img/tablet/nav-${getCurrentLang}.svg`)" >
                    <img class="d-flex nav-bg w-100" 
                    :src="require(`@/assets/img/nav-${getCurrentLang}.svg`)" >
                </picture>
                <picture>
                    <source media="(max-width:992px)"
                    srcset="@/assets/img/tablet/menu-icon.svg">
                    <img src="@/assets/img/mobile/menu-icon.svg"
                    alt="menu-icon"
                    class="w-10vw position-absolute d-lg-none d-flex my-auto pl-80px t-0 b-0 left-0 pointer"
                    @click="openMenu">
                </picture>
           
            </div>
            <ul class="navbar-nav px-80px position-absolute nav-bg d-none d-lg-flex">
                <li class="nav-item">
                    <a class="nav-link active medium f-20" type="button" @click="navigateTo('about-section')">{{
                        $t('about-jumlatech') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20" type="button" @click="navigateTo('how-section')">{{ $t('how-to-work')
                    }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link  medium f-20" type="button" @click="navigateTo('partners-section')">{{
                        $t('our-partners') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20" type="button" @click="navigateTo('who-section')">{{ $t('who-are-we')
                    }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20" type="button" @click="navigateTo('contact-section')">{{ $t('contact-us')
                    }}</a>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link medium f-20" :to="{ name: 'jobs' }">{{ $t('jobs-title') }}</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20" type="button" @click="navigateTo('download-section')">{{
                        $t('download-the-app') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link medium f-20" type="button" @click="changeLang">{{ $t('lang') }}</a>
                </li>

            </ul>
        </div>
    </nav>
</template>
<script>
export default {
    methods: {
        openMenu() {
            return this.$store.dispatch('setMenu', true)
        },
        navigateTo(id) {
            if (this.currentRouteName == 'home' && document.getElementById(`${id}`))
                document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
            else {
                this.$router.push({ name: 'home' });
                setTimeout(() => {
                    document.getElementById(`${id}`).scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        },
        changeLang() {
            if (this.$store.getters.getLocal == 'ar')
                this.$store.dispatch('setCurrentLocale', 'en')
            else
                this.$store.dispatch('setCurrentLocale', 'ar')
                window.location.reload();
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    }
}
</script>
<style scoped>
.w-10vw{
    width: 10vw;
    display: flex;
}
.arabic .left-0{
    left: 0;
}
.english .left-0{
    right: 0;
}
.navbar {
    position: relative;
    z-index: 2;
    height: var(--nav);
}

.nav-bg {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.navbar-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.px-80px {
    padding-left: 4.2vw;
    padding-right: 4.2vw;
}

.nav-item .nav-link,
.nav-item .nav-link.active {
    color: white;
    padding: 0 !important;
}

.arabic .nav-item .nav-link,
.arabic .nav-item .nav-link.active {
    margin-right: 2.8vw;
}

.english .nav-item .nav-link,
.english .nav-item .nav-link.active {
    margin-left: 2.8vw;
}

@media (max-width: 992px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    .navbar {
        height: var(--nav-mobile);
    }
}
@media (min-width: 1000px) and (max-width: 1200px) {
    .english .nav-item .nav-link,
    .english .nav-item .nav-link.active {
    margin-left: 1.8vw;
}
.arabic .nav-item .nav-link,
.arabic .nav-item .nav-link.active{
    margin-right: 2vw;
}
}
</style>
