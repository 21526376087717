<template>
    <section id="reviews-section" class="container-fluid position-relative">
        <div class="row">
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow">
                <img class="px-80px d-none d-lg-flex w-100" :src="require('@/assets/img/shadow.svg')" />
            </div>
            <div class="col-lg-5  col-12  text-center  d-flex flex-lg-row flex-column position-relative">
                <div class="block block d-flex justify-content-between justify-content-lg-center flex-column  h-100"
                    :class="{ 'pr-80px': lg || xl }">
                    <div class="w-fit-content position-relative text-center mx-auto">
                        <!-- <img class="position-absolute text-center t-0  mx-auto w-23 d-xl-none d-block" src="@/assets/img/feedbacks-deco.svg" alt="" v-show="getCurrentLang=='en'"> -->
                        <Title :title="$t('feedback-title')" class="w-fit-content mt-xl-2" />
                    </div>
                    <swiper :loop="true" :autoplay="{
                        delay: 10000,
                        disableOnInteraction: false,
                    }" :pagination="{clickable: true}" :modules="modules"
                        class="mySwiper py-lg-4 pt-2 pb-md-5 pb-4 pt-xxl-3 pb-xxl-5 pb-4">
                        <swiper-slide
                        v-for="feedback in feedbacks"
                        :key="feedback.id"
                            class="w-fit-content  w-100 position-relative d-flex justify-content-center align-items-center">
                            <picture>
                                <source media="(max-width:767.98px)" srcset="@/assets/img/mobile/review-slide.svg">
                                <source media="(max-width:1000px)" srcset="@/assets/img/tablet/review-slide.svg">
                                <img class="slider-height w-100" src="@/assets/img/review-slide.svg" alt="">
                            </picture>
                            <div class="text text-center position-absolute w-63">
                                <h5 class="f-35 bold white mb-1">
                                {{ feedback[this.$store.getters.getLocal??'en']['name'] }}
                                </h5>
                                <!-- <p class="f-25 semibold white mb-1">
                                    سامر صويري
                                </p>  -->
                                <p class="f-26 light white mb-0 feedback-content">
                                    {{ feedback[this.$store.getters.getLocal??'en']['feedback'] }}
                                </p>
                            </div>
                        </swiper-slide>
                
                    </swiper>
                </div>
            </div>
            <div class="col-lg-7 col-12  px-0  position-relative">
                <picture class="h-100  d-flex justify-content-end mt-auto  ">
                    <source media="(max-width:575.98px)"
                        :srcset="require(`@/assets/img/mobile/review-section-${getCurrentLang}.svg`)">
                    <source media="(max-width:1000px)"
                        :srcset="require(`@/assets/img/tablet/review-section-${getCurrentLang}.png`)">

                    <img :src="require(`@/assets/img/review-section-${getCurrentLang}.svg`)"
                        class="max-h-90 max-w-100 section-img">
                </picture>
            </div>

        </div>

    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
export default {
    data() {
        return {
            feedbacks: [
                {
                    id: '1',
                    ar: {
                        feedback: 'حسيت حالي بحضارة، عندي أصناف كتير بالكمية يلي بدي ياها',
                        name: 'سامر صويري'
                    },
                    en: {
                        feedback: 'I like the modern take on wholesale, I find everything I need and I can order in the quantities of my choice.',
                        name: 'Samer Sweri'
                    }
                },
                {
                    id: '2',
                    ar: {
                        feedback: 'لقيت أسعار أرخص من السوق و حتى في شغلات مفقودة بالسوق بلاقيا على التطبيق',
                        name: 'سليم الخوري'
                    },
                    en: {
                        feedback: 'Prices are great, even cheaper than buying products from the market! I even find products that are out of stock in the market.',
                        name: 'Salim Al Khouri'
                    }
                },
                {
                    id: '3',
                    ar: {
                        feedback: 'ماني مضطر استنى الشركة لتجي أو انزل على محلات الجملة، بالتطبيق شو ما بدي بيجي لعندي',
                        name: 'ميلاد'
                    },
                    en: {
                        feedback: 'I don’t have to wait for the company rep to come to my shop or go to the wholesale store to buy my products! Everything comes to me whenever I want!',
                        name: 'Milad'
                    }
                },
                {
                    id: '4',
                    ar: {
                        feedback: 'التطبيق عملي جداً و مريح جداً و تعامل الناس معو جداً ممتاز',
                        name: 'عادل محمد سماقية'
                    },
                    en: {
                        feedback: 'The app is very practical and extremely convenient, and the people who work there are amazing!',
                        name: 'Adel Mohammad Semakye'
                    }
                },
                {
                    id: '5',
                    ar: {
                        feedback: 'بنصح أي حدا عندو بقالية ينزل التطبيق لأنو رح يرتاح كتير و رح يساعدو بشغلو',
                        name: 'شادي خليل'
                    },
                    en: {
                        feedback: 'I recommend anyone with a grocery store to download the app because it will make their life much easier and help with their business.',
                        name: 'Shadi Khalil'
                    }
                },
                {
                    id: '6',
                    ar: {
                        feedback: 'التخديم كتير ممتاز و عندن سرعة بالأداء، اذا احتجت غرض تاني يوم عم يكون عندي',
                        name: 'صهيب'
                    },
                    en: {
                        feedback: 'The service is excellent, and they are very efficient. I just place the order and I get all my products the next day!',
                        name: 'Souhaib '
                    }
                },
                {
                    id: '7',
                    ar: {
                        feedback: 'التطبيق كتير حلو، واجهتو كتير سهلة و التعامل معو حلو و في عروض كتير',
                        name: 'أحمد حسن'
                    },
                    en: {
                        feedback: 'I love the App, very user-friendly! They always have offers and great deals.',
                        name: 'Ahmad Hasan'
                    }
                },

            ]
        }
    },
    mounted() {
        // document.querySelector('#reviews-section .row').style.height = window.innerHeight + 'px';
    },
    components: {
        Swiper,
        SwiperSlide, Title
    },
    computed: {
        xl() {
            return this.$store.getters.isXl;
        },
        lg() {
            return this.$store.getters.isLg;
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
    setup() {
        return {
            modules: [Autoplay, Pagination],
        };
    },
}
</script>
<style scoped>
.feedback-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical;
}

.mySwiper {
    width: 37vw
}

.w-63 {
    width: 63%
}

.max-w-100 {
    max-width: 100%;
}

.max-h-90 {
    max-height: 90%;
    margin-top: auto;
}

.w-23 {
    max-width: 110%;
    left: -5%;
}

@media (max-width: 1600px) {
    .max-h-90 {
        max-height: 90%;
        margin-top: auto;
    }

}

@media (max-width: 991.98px) {
    .mySwiper {
        width: 98%
    }

    .w-63 {
        width: 63%
    }

    .max-h-90.section-img {
        max-height: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        width: 99%;
    }

    /* .max-w-100.section-img {
        width: 100%
    } */
}

@media (min-width: 567.98px) and (max-width: 992px) {
    .slider-height {
        height: 38.026vh;
    }
}</style>