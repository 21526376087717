<template>
    <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }" class="d-flex flex-column">
        <div class="display-flex">
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.name }">
                <Field name="name" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                    :placeholder="$t('fname')" />
                <span class="f-14 position-absolute error-color">{{ errors.name }}</span>
                <img v-show="errors.name" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.last_name }">
                <Field name="last_name" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                    :placeholder="$t('lname')" />
                <span class="f-14 position-absolute error-color">{{ errors.last_name }}</span>
                <img v-show="errors.last_name" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.email }">
                <Field name="email" class="input-style-basic white input-style f-20 semibold" type="email"
                    autocomplete="off" :placeholder="$t('email')" />
                <span class="f-14  error-color position-absolute">{{ errors.email }}</span>
                <img v-show="errors.email" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.phone_number }">
                <Field name="phone_number" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                    :placeholder="$t('phone')" />
                <span class="f-14  error-color position-absolute">{{ errors.phone_number }}</span>
                <img v-show="errors.phone_number" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>

            <div class="input-group full d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.introduction }">
                <Field class="input-style-basic white  input-style f-20 semibold" type="text"
                    :placeholder="$t('introduction')" name="introduction" />
                <span class="f-14  error-color position-absolute">{{ errors.introduction }}</span>
                <img v-show="errors.introduction" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>
        </div>
        <div class="d-flex">
            <input type="file" id="fileInput" accept="image/*,.pdf" class="d-none">
            <div class="input-group cv d-flex flex-column h-fit-content justify-content-center" @click="openBrowse">
                <input class="input-style-basic white  input-style f-20 semibold" type="text" :placeholder="$t('cv')"
                    name="cv" value="" disabled id="blah" />
                <span class="f-14 mx-2 mx-lg-0 orange position-absolute" v-show="isFileError">{{ $t('no-file') }}</span>
                <!-- <img v-show="errors.introduction" src="@/assets/img/x.svg" class="position-absolute left-0" alt=""> -->
            </div>
            <div class="bg-light-blue h-fit-content mx-lg-4  pointer" @click="openBrowse">
                <p class="mb-0 white f-18 bold">
                    {{ $t('choose-file') }}
                </p>
            </div>
        </div>
        <button
            class="btn btn-style-basic w-fit-content px-0  mt-xxl-0 mt-3 pt-0 d-flex align-items-center  pointer position-relative">
            <img src="@/assets/img/contactFormBtn.svg" alt="">
            <p class="position-absolute text-center l-0 r-0 mb-0 f-24 semibold white">
                {{ $t('apply') }}
            </p>
        </button>
    </Form>
</template>
<script>
import { required, email } from '@vee-validate/rules';
import { Form, Field } from 'vee-validate';
import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { setLocale } from '@vee-validate/i18n';
import ServiceFactory from '@/services/ServiceFactory';


const jobs = ServiceFactory.get('jobs')
defineRule('required', required);
defineRule('email', email);

configure({
    generateMessage: localize({
        en: {
            names: {
                name: 'First Name',
                last_name: 'Last Name',
                phone_number: "Phone Number",
                email: 'email',
                introduction: 'Short Introduction',
            },
            messages: {
                required: 'This field is required',

            },
        },
        ar: {
            names: {
                name: 'الاسم',
                last_name: 'الاسم الأخير',
                phone_number: "الموبايل",
                email: 'البريد الالكتروني',
                introduction: 'مقدمة قصيرة'
            },
            messages: {
                required: 'هذا الحقل مطلوب',
            },
        },
    }),
});
export default {
    mounted() {
        setLocale(this.$store.getters.getLocal);
        var here = this;
        fileInput.onchange = evt => {
            here.err = true;
            blah.value = '';
            let cv = evt.target.files[0];
            here.fd = new FormData();
            here.fd.append('file', cv)
            const [file] = fileInput.files
            if (file) {
                blah.value = file.name;
                here.err = false
            }
        }
    },
    data() {
        return {
            schema: {
                last_name: 'required',
                name: 'required',
                phone_number: 'required',
                email: 'email|required',
                introduction: 'required',
            },
            job_id: '',
            fd: new FormData(),
            err: true
        }
    },
    components: {
        Form,
        Field
    },
    methods: {
        async submit(values, { resetForm }) {
            var here = this;
            const { name: _, ...clone } = { ...values, job_id: '1' };
            Object.keys(clone).forEach(function (key) {
                if (clone[key] != undefined)
                    if (key == 'last_name')
                        here.fd.append('name', values.name + ' ' + clone[key]);
                    else
                        here.fd.append(key, clone[key]);
            })

            if (here.fd.has('file')) {
                var response = await jobs.apply(here.fd);
                if (response) {
                    this.$swal('');
                    this.$swal.fire({
                        icon: 'success',
                        timer: 1500,
                        text: this.$root.$t('succ'),
                    })
                    resetForm();
                    this.reset();
                } else {
                    this.$swal.fire({
                        icon: 'error',

                        text: 'Something went wrong ,Please try again',
                    })
                }
            }
            else
                this.err = true;
        },
        openBrowse() {
            document.getElementById('fileInput').click();
        },
        reset() {
            document.getElementById('blah').value = '';
            document.getElementById("fileInput").value = "";
            this.fd = new FormData();
        }
    },
    computed: {
        isFileError() {
            return this.err
        }
    }
}
</script>
<style scoped>
.cv {
    width: 43.5%;
}

.display-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.display-flex .input-group {
    width: 43.5%;
    margin-bottom: 4.5%;
}

.display-flex .input-group.full {
    width: 100%;
    margin-bottom: 4%;

}

.orange {
    color: #EB6500;

}

.english .orange {
    right: 0;
}

.arabic .orange {
    left: 0;
}

.arabic .error-color {
    color: #EB6500;
    left: 7%;
}

.english .error-color {
    color: #EB6500;
    right: 7%;
}

.arabic .left-0 {
    left: 0;
}

.english .left-0 {
    right: 0;
}

.english .display-flex .input-group:last-child .error-color {
    right: 3%;
}

.arabic .display-flex .input-group:last-child .error-color {
    left: 3%;
}

.bg-light-blue {
    background-color: #4764AF;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

/* .display-flex .input-group.full .error-color {
    left: 3%;
} */

.btn-style-basic img {
    width: 9.6vw
}

@media (max-width: 991.98px) {
    .display-flex .input-group {
        width: 100%;
        margin-bottom: 4.5%;
    }
.btn-style-basic img{
    width: 21.8vw;
}
  
    .cv {
        width: 67%;
    }

    .bg-light-blue {
        width: 33%;
        text-align: center;
    }
   


}
@media (max-width: 567.98px) {
    .btn-style-basic img {
        width: 38.8vw;
    }

}
</style>