<template>
    <section class="container-fluid position-relative px-0" id="partners-section">
        <div class="row position-relative mx-0 w-100">
            <div class="col-12 text-center mb-xxl-5 pb-xxl-5 mb-3 pb-3 mt-11vh">
                <Title :title="$t('our-partners-title')" class="mt-0" />
                <p class="f-24 white text-center light" v-html="$t('our-partners-desc')">
                </p>
            </div>
            <div class="col-12 px-0 mb-xxl-5 px-0 mb-5 position-relative h-fit-content"
                v-if="fetch && (first ? getBrands() : true)">
                <img loading="lazy" src="@/assets/img/partners-top.svg"
                    class="position-absolute r-0 b-100 w-5vw d-lg-none d-block" alt="">
                <img loading="lazy" src="@/assets/img/partners-bottom.svg"
                    class="position-absolute  l-0 t-100 w-2vw d-lg-none d-block" alt="">
                <div
                    class="mx-auto d-flex flex-lg-column flex-row d-ltr bg-white position-relative pt-lg-5 pb-lg-4 py-1 partners">
                    <Vue3Marquee :vertical="!xl && !lg" :clone="true" :duration="brands.length" class="px-3 marq">
                        <div class="brand" v-for="image in getHalf('first')" :key="image.id">
                            <img class="w-90" :src="image.image" />
                        </div>
                    </Vue3Marquee>
                    <Vue3Marquee :vertical="!xl && !lg" class="px-3 marq" :clone="true" :direction="'reverse'"
                        :duration="brands.length">
                        <div class="brand" v-for="image in getHalf('last')" :key="image.id">
                            <img class="w-90" :src="image.image" />
                        </div>
                    </Vue3Marquee>
                    <picture>
                        <source media="(max-width:991.98px)" srcset="@/assets/img/mobile/top-partner.svg">
                        <img loading="lazy" src="@/assets/img/top-partner.svg"
                            class="position-absolute z-1 r-0 t-0 bg-blue  w-100 ">
                    </picture>
                    <picture>
                        <source media="(max-width:991.98px)" srcset="@/assets/img/mobile/bottom-partner.svg">
                        <img loading="lazy" src="@/assets/img/bottom-partner.svg"
                            class="position-absolute z-1 l-0   b-0 w-100  ">
                    </picture>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import { Vue3Marquee } from 'vue3-marquee'
import ServiceFactory from '@/services/ServiceFactory'
const partners = ServiceFactory.get('partners')
export default {
    props: {
        fetch: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            first: true,
            brands: [],
        }
    },
    mounted() {
        // this.getBrands();
        // document.querySelector('#partners-section .row').style.height = window.innerHeight + 'px';
    },
    components: {
        Title,
        Vue3Marquee
    },
    computed: {
        xl() {
            return this.$store.getters.isXl
        },
        lg() {
            return this.$store.getters.isLg
        }

    },
    methods: {
        async getBrands() {
            if (this.first) {
                var response = await partners.list();
                if (response && response.length > 0) {
                    this.brands = response;
                }
            }
            this.first = false
        }
        , getHalf(n) {
            if (this.brands.length >= 10)
                var midpoint = Math.ceil(this.brands.length / 2);
            if (n == 'first')
                return this.brands.slice(0, midpoint);
            else if (n == 'last')
                return this.brands.slice(-midpoint);
            return []
        }
    }
}
</script>
<style scoped>
.marq {
    height: 50%;
}

.w-5vw {
    width: 5.25vw
}

.w-2vw {
    width: 3.33vw
}

.b-100 {
    bottom: 100%;
}

.t-100 {
    top: 100%;
}

.partners {
    height: 60vh;
    width: 100%
}

.max-h-100 {
    max-height: 100%;
}

.bg-white {
    background-color: white;
}

.d-ltr {
    direction: ltr;
}

.brand {
    width: 13vw;
}

.w-90 {
    width: 90%;
}

.mt-20 {
    margin-top: 20.9vh
}

.mt-11vh {
    margin-top: 11vh;
}

@media (max-width: 991.98px) {
    .marq {
        width: 50% !important;
        height: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .brand {
        width: 28vw;
    }

    .mt-11vh {
        margin-top: 5.5vh;
    }

    .mt-20 {
        margin-top: 0rem !important;
    }

    .mt-20:last-child {
        margin-top: 1.5rem !important;
    }

    .w-90 {
        width: 100%;
    }

}

@media (max-width: 390px) and (max-height:670px) {
    .mt-11vh {
        margin-top: 7vh
    }

}

@media (min-width: 567.98px) and (max-width: 992px) {
    .partners {
        height: 69vh;
        width: 73.59vw;
    }

    .w-5vw {
        width: 3.25vw;
        right: 9vw;
    }

    .w-2vw {
        left: 9vw
    }

}
</style>