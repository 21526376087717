<template>
    <div class="col-lg-6 col-12" v-if="lg || xl">
        <img class="position-absolute animate__animated  b-0 left-0  w-68 slider-img"
            :class="getCurrentLang == 'en' ? 'animate__fadeInRight' : 'animate__fadeInLeft'"
            :src="require(`@/assets/img/step-1-${getCurrentLang}.png`)" />

        <img class="position-absolute  b-0 left-0 w-58 mx-auto slider-img"
            :src="require(`@/assets/img/step-2-${getCurrentLang}.png`)" />

        <img :src="require(`@/assets/img/step-3-${getCurrentLang}.png`)"
            class="position-absolute  b-0  w-53 left-0 slider-img" />

        <img :src="require(`@/assets/img/step-4-${getCurrentLang}.png`)"
            class="position-absolute  b-0  w-53 left-0 slider-img" />

        <img :src="require(`@/assets/img/step-5-${getCurrentLang}.png`)"
            class="position-absolute  b-0  w-53 left-0 slider-img" />
    </div>
    <div class="col-12 text-center" v-else>
        <picture>
            <source media="(max-width:576px)" :srcset="require(`@/assets/img/mobile/mstep1-${getCurrentLang}.png`)">
            <img :src="require(`@/assets/img/tablet/mstep1-${getCurrentLang}.png`)"
                class="position-absolute animate__animated  b-0 l-0 w-100 r-0  slider-img"
                :class="getCurrentLang == 'en' ? 'animate__fadeInRight' : 'animate__fadeInLeft'" alt="">
        </picture>
        <picture>
            <source media="(max-width:576px)" :srcset="require(`@/assets/img/mobile/mstep2-${getCurrentLang}.png`)">
            <img :src="require(`@/assets/img/tablet/mstep2-${getCurrentLang}.png`)"
                class="position-absolute  b-0 l-0 r-0  w-100 mx-auto slider-img" alt="">
        </picture>
        <picture>
            <source media="(max-width:576px)" :srcset="require(`@/assets/img/mobile/mstep3-${getCurrentLang}.png`)">
            <img :src="require(`@/assets/img/tablet/mstep3-${getCurrentLang}.png`)"
                class="position-absolute  b-0  w-100 l-0 r-0  slider-img" alt="">
        </picture>
        <picture>
            <source media="(max-width:576px)" :srcset="require(`@/assets/img/mobile/mstep4-${getCurrentLang}.svg`)">
            <img :src="require(`@/assets/img/tablet/mstep4-${getCurrentLang}.png`)"
                class="position-absolute  b-0  w-100 l-0 r-0  slider-img" alt="">
        </picture>
        <picture>
            <source media="(max-width:576px)" :srcset="require(`@/assets/img/mobile/mstep5-${getCurrentLang}.svg`)">
            <img :src="require(`@/assets/img/tablet/mstep5-${getCurrentLang}.png`)"
                class="position-absolute  b-0  w-100 l-0 r-0  slider-img" alt="">
        </picture>
    </div>
</template>
<script>

export default {
    computed: {
        xl() {
            return this.$store.getters.isXl
        },
        lg() {
            return this.$store.getters.isLg
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    },
}
</script>
<style scoped>
.animate__fadeOut {
    animation-duration: .4s
}

.animate__fadeInLeft,
.animate__fadeInRight {
    animation-duration: 1.2s;
    animation-delay: .4s
}

.w-58 {
    width: 58%
}

.w-53 {
    width: 53%
}


.w-68 {
    width: 68%;
}

.arabic .left-0 {
    left: 0;
}

.english .left-0 {
    right: 0;
}

.slider-img:not(.animate__animated) {
    opacity: 0;
}

@media (min-width: 575.98px) and (max-width: 992px) {
    .slider-img {
        max-height: 50vh;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 576px) {

    .slider-img {
        max-height: 35vh;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .w-68 {
        width: 51%;
    }

    .w-58 {
        width: 50%;
    }

    .w-53 {
        width: 50%;
    }
}
</style>