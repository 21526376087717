<template>
    <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }" class="d-flex flex-column">
        <div class="display-flex">
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.name }">
                <Field name="name" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                    :placeholder="$t('name')" />
                <span class="f-14 position-absolute error-color">{{ errors.name }}</span>
                <img v-show="errors.name" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">
            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.store_name }">
                <Field name="store_name" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                :placeholder="$t('store_name')" />
                <span class="f-14  error-color position-absolute">{{ errors.store_name }}</span>
                <img v-show="errors.store_name" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">

            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.phone_number }">
                <Field name="phone_number" class="input-style-basic white input-style f-20 semibold" autocomplete="off"
                :placeholder="$t('phone_number')" />
                <span class="f-14  error-color position-absolute">{{ errors.phone_number }}</span>
                <img v-show="errors.phone_number" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">

            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.region }">
                <Field name="region" class="input-style-basic white input-style f-20 semibold" type="text"
                    autocomplete="off" :placeholder="$t('region')" />
                <span class="f-14  error-color position-absolute">{{ errors.region }}</span>
                <img v-show="errors.region" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">

            </div>
            <div class="input-group  d-flex flex-column h-fit-content justify-content-center"
                :class="{ 'error-input': errors.details }">
                <Field class="input-style-basic white input-style f-20 semibold" type="text"  :placeholder="$t('details')"
                    name="details" />
                    <span class="f-14  error-color position-absolute">{{ errors.details }}</span>
                <img v-show="errors.details" src="@/assets/img/x.svg" class="position-absolute left-0" alt="">

            </div>
        </div>
        <button
            class="btn btn-style-basic w-fit-content px-0 mt-xxl-0 mt-3 pt-0 d-flex align-items-center  pointer position-relative">
            <img src="@/assets/img/contactFormBtn.svg" alt="">
            <p class="position-absolute text-center l-0 r-0 mb-0 f-24 semibold white">
                {{ $t('send') }}
            </p>
        </button>
    </Form>
</template>
<script>
import { required, email } from '@vee-validate/rules';
import { Form, Field } from 'vee-validate';
import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import { setLocale } from '@vee-validate/i18n';
import ServiceFactory from '@/services/ServiceFactory';


const customerService = ServiceFactory.get('customer')
defineRule('required', required);

configure({
    generateMessage: localize({
        en: {
            names: {
                name: 'Name',
                store_name: 'Store Name',
                phone_number: "Phone Number",
                region: 'Region',
                details: 'Detailed Address'
            },
            messages: {
                required: 'This field is required',
            },
        },
        ar: {
            names: {
                name: 'الاسم',
                store_name: 'اسم المحل',
                phone_number: "الموبايل",
                region: 'المنطقة',
                details: 'إضافة ملاحظات'
            },
            messages: {
                required: 'هذا الحقل مطلوب',
            },
        },
    }),
});
import emailjs from '@emailjs/browser';

export default {
    mounted() {
        setLocale(this.$store.getters.getLocal);
    },
    data() {
        return {
            schema: {
                store_name: 'required',
                name: 'required',
                phone_number: 'required',
                region: 'required',
                details: 'required',
            },
        }
    },
    components: {
        Form,
        Field
    },
    methods: {
        async submit(values) {
            try {
                let response = await emailjs.send("service_4v9ktsj", "template_0rzryor", {
                    name: values.name,
                    customer_type:'Store owner',
                    store: values.store_name,
                    country: values.region,
                    phone: values.phone_number,
                    email: '-',
                    message: values.details,
                    reply_to: 'info@jt.sy'
                }, "WXLFWLTIBakgnf2sX");
                  this.$swal('');
                  this.$swal.fire({
                    icon: 'success',
                    timer: 1500,
                    text: this.$root.$t('succ'),
                  })

            }
            catch (error) {
                  this.$swal.fire({
                    icon: 'error',
                    timer: 1500,
                    text: 'Something went wrong ,Please try again',
                  })
                //   this.$store.commit('toggleLoader')
            }
        },
    },
    computed: {
        locale() {
            // setLocale(this.$store.getters.getCurrentLocale)
            return this.$store.getters.getCurrentLocale;
        }
    }
}
</script>
<style scoped>
.display-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.display-flex .input-group {
    width: 46%;
    margin-bottom: 4.5%;
}

.display-flex .input-group:last-child {
    width: 100%;
    margin-bottom: 4%;

}



.btn-style-basic img {
    width: 9.6vw
}

.arabic .error-color {
    color: #EB6500;
    left: 7%;
}
.english .error-color {
    color: #EB6500;
    right: 7%;
}
.english  .display-flex .input-group:last-child .error-color {
    right: 3%;
}
.arabic .display-flex .input-group:last-child .error-color {
    left: 3%;
}
.arabic .left-0{
    left: 0;
}
.english .left-0{
    right: 0;
}
@media (max-width: 991.98px) {
    .display-flex .input-group {
        width: 100%;
        margin-bottom: 4.5%;
    }
    .btn-style-basic img {
        width: 21.8vw;
    }

    .arabic .error-color {
    color: #EB6500;
    left: 8%;
}
.english .error-color {
    color: #EB6500;
    right: 8%;
}
.english  .display-flex .input-group:last-child .error-color {
    right: 4%;
}
.arabic .display-flex .input-group:last-child .error-color {
    left: 4%;
}
}
@media (max-width: 567.98px) {
    .btn-style-basic img {
        width: 38.8vw;
    }

}
</style>