<template>
    <section class="container-fluid position-relative overflow-hidden" id="how-section">
        <div class="row overflow-hidden" :class="{ 'stop-resize': (!lg && !xl) }">
            <div class="col-12 text-center position-relative z-4 px-0">
                <div class="text px-80px">
                    <Title :title="$t('how-title')" class="mt-0" />
                    <p class="f-24 white text-center light mb-0" v-html="$t('how-desc')"></p>
                    <div v-if="lg || xl"
                        class="pointer w-fit-content-height-fit-content position-relative d-flex align-items-center justify-content-center anchor"
                        @click="openPopup">
                        <img src="@/assets/img/how-btn.svg" class="w-8" alt="">
                        <p class="position-absolute l-0 r-0 pointer f-20 white mb-0">
                            {{ $t('how-btn-text') }}
                        </p>
                    </div>
                    <a v-else
                        class="pointer w-fit-content-height-fit-content position-relative d-flex align-items-center justify-content-center anchor"
                        href="https://www.youtube.com/watch?v=rcp5rnUQnkE"
                        target="_blank">
                        <img src="@/assets/img/mobile/how-btn.svg" class="w-8" alt="">
                        <p class="position-absolute l-0 r-0 pointer f-20 white mb-0">
                            {{ $t('how-btn-text') }}
                        </p>
                    </a>
                </div>
                <picture v-if="!lg && !xl" >
                    <source media="(max-width:575.98px)"
                    :srcset="require(`@/assets/img/mobile/road-${getCurrentLang}.svg`)" >
                    <img :src="require(`@/assets/img/tablet/road-${getCurrentLang}.svg`)" class="max-h-100 w-100" alt="">
                </picture>
            </div>
            <div class="road" v-if="lg || xl">
                <!-- road -->
                <img src="@/assets/img/road.svg" class="z-3 w-100 px-0 l-0 r-0 b-0 position-absolute road road-line" />
                <img src="@/assets/img/road-shades.png" class=" z-1 w-100 r-0 l-0 b-0 position-absolute" />
                <!-- track -->
                <img :src="require(`@/assets/img/road-track-${getCurrentLang}.svg`)" class=" z-3 w-119 right-0 b-0 position-absolute road-track" id="how-road-track" />
                <!-- text popups -->
                <img :src="require(`@/assets/img/road-steps/step1-${getCurrentLang}.svg`)" class="right-0 b-0 position-absolute step-1 z-2 road-step" alt="">
                <img :src="require(`@/assets/img/road-steps/step2-${getCurrentLang}.svg`)"
                    class="r-0 l-0 mx-auto b-0 position-absolute step-2 z-2 road-step" alt="">
                <img :src="require(`@/assets/img/road-steps/step3-${getCurrentLang}.svg`)" class="left-0 b-0 position-absolute step-3 z-2 road-step" alt="">
            </div>
        </div>
        <TvPopup v-if="isOpen" @close="close"/>
    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import TvPopup from '@/components/sections_subs/TvPopup.vue'
export default {
    data(){
        return{
            open:false
        }
    },
    methods:{
        close(){
            this.open = false ;
        },
        openPopup(){
            this.open = true;
        }
    },
    components: { Title ,TvPopup},
    computed: {
        xl() {
            return this.$store.getters.isXl
        },
        lg() {
            return this.$store.getters.isLg
        },
        getCurrentLang(){
            return this.$store.getters.getLocal
        },
        isOpen(){
            return this.open
        }
    },
    mounted() {
        if (this.lg || this.xl) {
            // document.querySelector('#how-section .row').style.height = window.innerHeight + 'px';
        }
    }
}
</script>
<style scoped>

.arabic .right-0,
.english .left-0{
    right: 0
}
.arabic .left-0,
.english .right-0{
    left: 0
}
.english  .road-line{
    transform: scaleX(-1);
}
.english .road-step {
    opacity: 0;
}
.arabic .road-step {
    opacity: 0;
}

.animate__animated.road-step {
    opacity: 1;
}

.w-8 {
    width: 10.41vw;
    margin-top: -.25%;
    margin-left: .5%;
}
.anchor{
    margin-top: -.25%;
    margin-left: .5%;
}
.english .anchor{
    margin-top: -.25%;
    margin-left: 2%;
}


.max-h-100 {
    max-height: 100%;
}

.arabic .animate-the-track {
    animation-name: animate-track;
    animation-duration: 6s;
    animation-fill-mode: forwards;
    transition: all 4s ease-in;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}
.english .animate-the-track {
    animation-name: animate-track-en;
    animation-duration: 6s;
    animation-fill-mode: forwards;
    transition: all 4s ease-in;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}
.step-1 {
    width: 33.33333333333333vw;
}

.step-2 {
    width: 33.33333333333333vw;
}

.step-3 {
    width: 33.33333333333333vw;
}

@keyframes animate-track {
    0% {
        transform: translate(0vw, 0vh) rotate(0deg);
    }

    16.66% {
        transform: translate(-10vw, -5vh) rotate(0deg);
    }
    17% {
        transform: translate(-10vw, -5vh) rotate(-27deg);
    }
    33.33% {
        transform: translate(-28vw, 2.5vh) rotate(-27deg);
    }
    34.5% {
        transform: translate(-28vw, 2.5vh) rotate(-7deg);
    }
    49.99% {
        transform: translate(-44vw, -2vh) rotate(-7deg);
    }
    51% {
        transform: translate(-44vw, -2vh) rotate(-22deg);
    }
    66.66% {
        transform: translate(-58vw, 2vh) rotate(-22deg);
    }
    70.5% {
        transform: translate(-60vw, 2vh) rotate(12deg);
    }
    83.3% {
        transform: translate(-72vw, -7vh) rotate(12deg);
    }
    90% {
        transform: translate(-77vw, -9vh) rotate(-34deg);
    }
    100% {
        transform: translate(-86vw, -1vh) rotate(-34deg);
    }
}
@keyframes animate-track-en {
    0% {
        transform: translate(0vw, 0vh) rotate(0deg);
    }

    16.66% {
        transform: translate(11vw, -5vh) rotate(0deg);
    }
    17% {
        transform: translate(11vw, -5vh) rotate(20deg);
    }
    33.33% {
        transform: translate(28vw, 2.5vh) rotate(20deg);
    }
    34.5% {
        transform: translate(28vw, 2.5vh) rotate(7deg);
    }
    49.99% {
        transform: translate(44vw, -2vh) rotate(7deg);
    }
    51% {
        transform: translate(44vw, -2vh) rotate(22deg);
    }
    66.66% {
        transform: translate(58vw, 2vh) rotate(22deg);
    }
    70.5% {
        transform: translate(60vw, 2vh) rotate(-12deg);
    }
    83.3% {
        transform: translate(72vw, -7vh) rotate(-12deg);
    }
    90% {
        transform: translate(79vw, -9vh) rotate(30deg);
    }
    100% {
        transform: translate(86vw, -1vh) rotate(30deg);
    }
}
.w-90 {
    width: 90%;
}

.mt-20 {
    margin-top: 20.9vh
}

.mt-11vh {
    padding-top: 11vh;
}

.w-119 {
    width: 11.9vw
}

@media (max-width: 992px) {
    .mt-11vh {
        padding-top: 5.5vh;
    }

    .mt-20 {
        margin-top: 0rem !important;
    }

    .mt-20:last-child {
        margin-top: 1.5rem !important;
    }

    .w-90 {
        width: 100%;
    }

}

@media (max-width: 390px) and (max-height:670px) {
    .mt-11vh {
        padding-top: 7vh
    }

}
@media (max-width: 566.98px){
    .w-8 {
    width: 44.41vw;}
}
@media (min-width: 567.98px) and (max-width: 992px) {
    .w-8 {
    width: 28.41vw;
}
}
@media (min-width: 992px) and (max-width: 1199.98px)  {
    .w-8{
    width: 15.41vw;
}
}
</style>