<template>
    <section class="container-fluid position-relative  overflow-xl-hidden" id="contact-section">
        <div class="row position-relative z-2 w-100 mx-0">
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow d-none d-lg-block">
                <img class="px-80px d-none d-lg-flex w-100" :src="require('@/assets/img/shadow.svg')" />
            </div>
            <div class="col-lg-6 col-12 px-0 ">
                <div :class="` px-80px ${active}`">
                    <Title :title="$t('contact-us')" />
                    <div
                        class="w-fit-content toggle position-relative d-flex align-items-center justify-contnent-around my-xxl-custom my-2 pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="222"
                            height="79" viewBox="0 0 222 79">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_10922" data-name="Rectangle 10922" width="222" height="79"
                                        transform="translate(1618 267)" fill="#fff" stroke="#707070" stroke-width="1" />
                                </clipPath>
                            </defs>
                            <g id="Mask_Group_12" data-name="Mask Group 12" transform="translate(-1618 -267)"
                                clip-path="url(#clip-path)">
                                <g id="Group_26786" data-name="Group 26786" transform="translate(-21090.758 -13182.332)">
                                    <g id="Group_26785" data-name="Group 26785" transform="translate(-214.677 -158.668)">
                                        <g id="Group_26784" data-name="Group 26784" transform="translate(0 0.202)">
                                            <path id="Path_281" data-name="Path 281"
                                                d="M1642.82,654.263l-221.52,2.513,3.2,72.24,211.471-3.4Z"
                                                transform="translate(21502.617 12956.535)" fill="#eb6500" />
                                            <path id="Path_281-2" data-name="Path 281"
                                                d="M1628.92,654.434l-215.877.574,3.664,67.364,207.41-1.022Z"
                                                transform="translate(21512.871 12959.769)" fill="#131730" />
                                            <path id="Path_27369" data-name="Path 27369"
                                                d="M-4.811-2.345l209.7,2.063-2.1,60.676L-.564,59.317Z"
                                                transform="translate(22933 13619)" fill="#27539f" />
                                        </g>
                                        <path id="Path_27370" data-name="Path 27370"
                                            d="M-3.836-.945,99.49.752l-2.76,55.52L0,56Z" transform="translate(22935 13620)"
                                            fill="#eb6500" />
                                        <path id="Path_16321" data-name="Path 16321" d="M1537.91,716.7"
                                            transform="translate(21579 12980)" fill="#c0d3ee" opacity="0.5" />
                                        <path id="Path_27370-2" data-name="Path 27370"
                                            d="M98.945.752-3.961-.7l2.885,56.967H95.307Z"
                                            transform="translate(23038.512 13620.714)" fill="#eb6500" opacity="0" />
                                    </g>
                                    <path id="Path_27371" data-name="Path 27371"
                                        d="M1557.76,672.371l-.641,25.592-49.947-26.118Z"
                                        transform="translate(21361.418 12791.187)" fill="#c0d3ee" opacity="0.35" />
                                </g>
                            </g>
                        </svg>
                        <p class="position-absolute py-3 mb-0 f-28 white semibold right-2  w-50 text-center"
                            @click="toggle('supplier')">
                            {{ $t('suppliers') }}
                        </p>
                        <p class="position-absolute py-3 mb-0 f-28 white semibold left-2 w-50 text-center"
                            @click="toggle('seller')">
                            {{ $t('seller') }}
                        </p>
                    </div>
                    <div class="seller-tab" v-if="isSeller">
                        <p class="f-24 medium white mb-4 d-none d-xxl-block">
                            {{ $t('seller-desc') }}
                        </p>
                        <p class="f-24 medium white mb-4  d-md-none d-block" v-html="$t('seller-desc-mobile')">
                        </p>
                        <p class="f-24 medium white mb-4  d-xxl-none d-md-block d-none" v-html="$t('seller-desc-tablet')">
                        </p>
                        <ContactForm />
                    </div>
                    <div class="supplier-tab" v-else>
                        <p class="f-24 medium white mb-4">
                            {{ $t('suppliers-desc') }}
                        </p>
                        <SupplierForm />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 px-0 d-flex position-relative" v-if="xl || lg">
                <img loading="lazy" src="@/assets/img/contact-section.png" class="animate__animated animate__fadeIn contact-section-img"
                    alt="" v-show="isSeller">
                <img loading="lazy" src="@/assets/img/supplier.svg" alt="" class="animate__animated animate__fadeIn contact-section-img"
                    v-show="!isSeller">
                <img loading="lazy" :src="require(`@/assets/img/contact-track-${getCurrentLang}.svg`)" alt=""
                    class="position-absolute b-0 mx-auto d-flex track d-none d-lg-block" width="240">
            </div>
        </div>

    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'
import ContactForm from '@/components/sections_subs/SellerForm.vue'
import SupplierForm from '@/components/sections_subs/SupplierForm.vue'

export default {
    data() {
        return {
            active: 'seller'
        }
    },
    mounted() {
        // document.querySelector('#contact-section .row').style.height = window.innerHeight + 'px';
    },
    components: {
    Title,
    ContactForm,
    SupplierForm,
},
    methods: {
        toggle(active) {
            this.active = active;
        }
    },
    computed: {
        isSeller() {
            return this.active == 'seller'
        },
        lg() {
            return this.$store.getters.isLg;
        },
        xl() {
            return this.$store.getters.isXl
        },
        getCurrentLang() {
            return this.$store.getters.getLocal
        }
    }
}
</script>
<style scoped>
.seller #Path_27370-2,
.supplier #Path_27370 {
    opacity: 0;
    transition: all .3s ease-in-out;
}

.seller #Path_27370,
.supplier #Path_27370-2 {
    opacity: 1;
    transition: all .3s ease-in-out;
}

.contact-section-img {
    max-height: 88%;
    width: 91.3%;
    margin-right: auto;
    max-width: 100%;
    margin-top: auto;
}

.toggle svg {
    width: 11.5vw;
}

.english .toggle svg {
    width: 12vw;
}

.l-100 {
    left: 100%;
}

.track {
    width: 12.5vw;
}

.arabic .track {
    left: 91.3%;
}

.english .track {
    right: 91.3%;
}

.right-2 {
    right: 2%
}

.left-2 {
    left: 2%
}

@media (max-width: 991.98px) {
    #contact-section .row {
        height: fit-content !important;
    }

    .english .toggle svg,
    .arabic .toggle svg {
        width: 50vw;
    }



}

@media (max-width: 568.98px) {
    .contact-section-img {
        width: 100%;
        height: auto;
        max-height: unset;
    }

    .toggle svg {
        width: 40vw;
    }

    .track {
        width: 12.5vw;
    }
}

@media (min-width: 1700px) {
    .my-xxl-custom {
        margin-top: 2rem !important;
        margin-bottom: 2.75rem !important;
    }
}

@media (min-width: 567.98px) and (max-width: 992px) {

    .english .toggle svg,
    .arabic .toggle svg {
        width:24vw;
    }

}

</style>
