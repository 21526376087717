<template>
    <div id="sidebar" class="d-xl-flex d-none flex-column align-items-start justify-content-start">
        <router-link  :to="{name:'about-app'}" class="f-30 bold d-flex flex-column mb-2 pb-1 border-bottom">
            {{ $t('about-the-app') }}:
        </router-link>
        <router-link :to="{name:'privacy-policy'}" class="f-30 bold mb-2 d-flex flex-column pb-1 border-bottom">
            {{ $t('privacy-policy') }}:
        </router-link>
        <router-link :to="{name:'terms-and-conditions'}" class="f-30 bold mb-2 d-flex flex-column pb-1 border-bottom">
            {{ $t('terms-and-conditions') }}:
        </router-link>
    </div>
</template>
<style scoped>
.arabic #sidebar{
    text-wrap: nowrap;
    margin-left: 5vw;
}
.english #sidebar{
    text-wrap: nowrap;
    margin-right: 5vw;
}
a{
    text-decoration: none;
    color:#4764AF;
}

.border-bottom{
    border-bottom: 2px solid #4764AF!important;
}
.active-link{
    color:#F48220;
    border-bottom: 2px solid #F48220!important;

}
</style>