<template>
  <teleport to="body" >
    <Menu :class="isMenu != 'init' ? (!isMenu ? 'close' : 'open') : 'd-none'" />
  </teleport>
  <div class="container-fluid px-0 position-relative content" :class="getCurrentLang == 'en' ? 'english' : 'arabic'">
    <div class="w-100" v-if="!lg && !xl">
      <img src="@/assets/img/mobile/left-full-decoration.svg"
        class="position-absolute z-0 l-0 t-0 nour b-0 max-w-100vw max-h-100">
      <img src="@/assets/img/mobile/right-full-decoration.svg"
        class="position-absolute z-0 r-0 t-0 b-0 max-w-100vw max-h-100">
    </div>
    <div class="w-100" v-if="lg || xl">
      <div class="position-absolute z-0 r-0  b-0 l-0  h-100 w-100 bg "></div>
    </div>
    <TheNavbar />
    <router-view />
    <TheFooter/>
  </div>
</template>
<script>

import TheNavbar from '@/components/layout/TheNavbar.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import Menu from '@/components/layout/Menu.vue'

export default {
  date() {
    return {
      screen: window.innerWidth
    }
  },
  components: {
    TheNavbar,
    TheFooter,
    Menu
  },
  mounted() {
    if (this.$store.getters.getLocal == 'ar' || this.$store.getters.getLocal == 'en')
      this.$store.dispatch('setCurrentLocale', this.$store.getters.getLocal)
    else
      this.$store.dispatch('setCurrentLocale', 'en')

    this.setScreenBreakpoints();
    // this.setSectionsHeight();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    isMenu() {
      return this.$store.getters.isMenu
    },
    xl() {
      return this.$store.getters.isXl
    },
    lg() {
      return this.$store.getters.isLg
    },
    getCurrentLang() {
      return this.$store.getters.getLocal
    }
  },
  methods: {
    onResize(e) {
      this.setScreenBreakpoints();
      // this.setSectionsHeight();
    },
    setScreenBreakpoints() {
      this.screen = window.innerWidth;
      this.$store.dispatch('setSm', this.screen >= 576 && this.screen < 768)
      this.$store.dispatch('setMd', this.screen >= 768 && this.screen < 992)
      this.$store.dispatch('setLg', this.screen >= 992 && this.screen < 1200)
      this.$store.dispatch('setXl', this.screen >= 1200)
    }
  }
}
</script>
<style scoped>
.max-h-100 {
  max-height: 100%;
}

.max-w-100vw {
  max-width: 100vw;
}

.h-100vh {
  height: 100vh;
}

.bg {
  background-image: url("@/assets/img/deco.svg");
  background-position-y: bottom;
  background-position-x: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>