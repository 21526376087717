<template>
    <section class="container-fluid overflow-y-hidden  position-relative overflow-x-hidden" id="download-section">
        <div class="row w-100 mx-0">
            <div class="position-absolute b-0 z-0 l-0 r-0 shadow">
                <img class="px-80px d-none d-lg-flex w-100" :src="require('@/assets/img/shadow.svg')" />
            </div>
            <div class="col-lg-6 col-12 px-0">
                <div class="px-80px h-100 p-end-lg-0 d-flex flex-column justify-content-between">
                    <div class="text">
                        <Title :title="$t('download-title')" />
                        <p class="f-40 mb-0 white light sub-title mt-3" v-html="$t('download-desc')">
                        </p>
                    </div>
                    <div v-if="lg || xl"
                        class="mr-80px overflow-y-hidden d-flex flex-column position-relative justify-content-end align-content-center download-menu">
                        <img src="@/assets/img/stand.svg" class="z-0  w-100 t-0 position-absolute" alt="">
                        <a href="https://play.google.com/store/apps/details?id=com.tms.jumlatech" target="__blank">
                            <img src="@/assets/img/googlePlay.svg" class="position-relative" alt="">
                        </a>
                        <a href="https://web.jt.sy/downloads/latest.apk" target="__blank">
                            <img :src="require(`@/assets/img/directDownload-${getCurrentLang}.svg`)"
                                class="position-relative" alt="">
                        </a>
                        <img src="@/assets/img/packages.svg" class="position-relative" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 px-0">
                <picture>
                    <source media="(max-width:575.98px)"
                        :srcset="require(`@/assets/img/mobile/mobile-download-section-${getCurrentLang}.svg`)">
                    <source media="(max-width:1000px)"
                        :srcset="require(`@/assets/img/tablet/download-section-${getCurrentLang}.png`)">
                    <img :src="require(`@/assets/img/download-section-${getCurrentLang}.png`)"
                        class="position-absolute b-0 left-0 w-68 z-2">
                </picture>
                <div v-if="!lg && !xl"
                    class=" overflow-y-hidden d-flex flex-column position-relative justify-content-lg-end justify-content-start align-content-center mb-5  mb-sm-0 mr-80px download-menu-mobile">
                    <img src="@/assets/img/mobile/mobile-stand.svg"
                        class="mobile-stand z-0 t-0 mx-auto t-0 position-absolute d-block h-100 b-0 d-lg-none" alt="">
                    <a href="https://play.google.com/store/apps/details?id=com.tms.jumlatech" target="__blank">
                        <img :src="require(`@/assets/img/mobile/mobile-google-play-${getCurrentLang}.svg`)"
                            class="position-relative" alt="">
                    </a>
                    <a href="https://web.jt.sy/downloads/latest.apk" target="__blank">
                        <img :src="require(`@/assets/img/mobile/mobile-direct-download-${getCurrentLang}.svg`)"
                            class="position-relative" alt="">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Title from '@/components/UI/Title.vue'

export default {
    mounted() {
        // document.querySelector('#download-section .row').style.height = window.innerHeight + 'px';
    },
    computed: {
        xl() {
            return this.$store.getters.isXl;
        },
        lg() {
            return this.$store.getters.isLg;
        },
        getCurrentLang() {
            return this.$store.getters.getLocal;
        }
    },
    components: {
        Title,
    }
}
</script>
<style scoped>
@media (min-width: 992px) {
    .arabic .pe-lg-0 {
        padding-left: 0 !important;
    }
}

/* #download-section {
    height: 100vh
} */

/* .mt-11vh {
    padding-top: 11vh;
} */

.w-68 {
    width: 68%
}

.arabic .left-0 {
    left: 0
}

.english .left-0 {
    right: 0
}

a,
.download-menu,
.download-menu-mobile {
    width: fit-content;
}

.download-menu-mobile {
    align-items: center;
    height: 58vh;
}

.download-menu-mobile img.position-relative {
    width: 76vw
}

.download-menu img.position-relative {
    width: 22.5vw
}


@media (max-width: 1700px) {
    .w-68 {
        width: 60%
    }

    .download-menu img.position-relative {
        width: 20vw
    }
}

@media (min-width: 567.98px) and (max-width: 992px) {
    .download-menu-mobile {
        align-items: center;
        height: 73vh;
    }

    .arabic .download-menu-mobile {
        margin-right: 13vw !important;
    }

    .english .download-menu-mobile {
        margin-left: 3vw !important;
    }

    .download-menu-mobile img.position-relative {
        width: 53vw;
    }



    .w-68 {
        width: 100%;
    }

    .mr-80px {
        margin-left: auto;
        margin-right: auto;
    }

    /* .mobile-stand {
        display: none;
    } */
}

/* @media (max-width: 992px) {
    .mt-11vh {
        padding-top: 4.2vh
    }

} */

@media (max-width: 567.99px) {
    .w-68 {
        width: 100%;
    }

}

@media (max-width: 400px) {
    .download-menu-mobile img.position-relative {
        width: 66vw;
    }
}

@media (max-width: 450px) and (max-height:750px) {
    .download-menu-mobile img.position-relative {
        width: 50vw
    }

    /* .mt-11vh {
        padding-top: 4vh
    } */

}

@media (min-width: 1200px) {
    .arabic .p-end-lg-0 {
        padding-left: 0;
    }

    .english .p-end-lg-0 {
        padding-right: 0;
    }
}
</style>