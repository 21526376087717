<template>
    <div class="blured animate__animated animate__fadeIn h-100 w-100 d-flex align-items-center position-fixed t-0 b-0 r-0 l-0">
        <img src="@/assets/img/close-tv.svg" class="position-absolute z-2 close pointer" @click="close" alt="">
        <div class="position-relative w-fit-content">
            <img src="@/assets/img/tv.svg" class="h-95 position-relative z-0 b-0 l-0" alt="">
            <iframe class="yout position-absolute" src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1">
            </iframe>
        </div>

    </div>
</template>
<script>
export default {
    emits:['close'],
    methods:{
        close(){
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.close {
    width: 3.4375vw;
    top: 4vh;
}
.english .close{
    left: 4vw;
}
.arabic .close{
    right: 4vw;
}
.yout {
    width: 51.4171vw;
    height: 59.466%;
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    z-index: 1;
    bottom: 14.194%;
}

.h-95 {
    /* max-height: 100%; */
    width: auto;
    right: auto;
    height: 100%;
    width: 90.36vw;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
}

.blured {
    overflow: hidden;
    backdrop-filter: blur(19px);
    z-index: 10;
    background-image: url('@/assets/img/tv-bg.png');
    background-position-y: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}
.arabic .blured{
justify-content: end;}
.english .blured{
justify-content: start;}
</style>