import LocaleHelper from '@/helpers/LocaleHelper'
import { createStore } from 'vuex'

export default createStore({
  state: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    menu:'init',
    locale: LocaleHelper.getCurrentLocale() ?? 'en'
  },
  getters: {
    isSm (state) {
      return state.sm
    },
    isMd (state) {
      return state.md
    },
    isLg (state) {
      return state.lg
    },
    isXl (state) {
      return state.xl
    },
    isMenu(state){
      return state.menu
    },
    getLocal(){
      return LocaleHelper.getCurrentLocale()
    }
  },
  mutations: {
    Sm (state, val) {
      state.sm = val
    },
    Md (state, val) {
      state.md = val
    },
    Lg (state, val) {
      state.lg = val
    },
    Xl (state, val) {
      state.xl = val
    },
    Menu(state, val){
      state.menu = val
    },
    CurrentLocale(state ,locale){
      LocaleHelper.changeLocale(locale)
    },
  },
  actions: {
    setSm ({ commit }, val) {
      commit('Sm', val)
    },
    setMd ({ commit }, val) {
      commit('Md', val)
    },
    setLg ({ commit }, val) {
      commit('Lg', val)
    },
    setXl ({ commit }, val) {
      commit('Xl', val)
    },
    setMenu ({ commit }, val) {
      commit('Menu', val)
    },
    setCurrentLocale({commit},local){
      commit('CurrentLocale',local)
    },  
  },
  modules: {}
})
