<template>
    <h2 class="f-80 bold white mb-3 mt-11vh">
        {{ title }}
    </h2>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>
<style scoped>
.mt-11vh {
    margin-top: 11%;
}

@media (max-width: 992px) {
    .mt-11vh {
        margin-top: 5.5vh;
    }

}

@media (max-width: 390px) and (max-height:670px) {
    .mt-11vh {
        margin-top: 7vh
    }

}
</style>