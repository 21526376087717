<template>
    <section class="container-fluid position-fixed z-4 t-0 b-0  primary-color-bg h-100" id="manager-card">
        <div class="row h-100">
            <div class="col-12 px-0 d-flex h-100">
                <img src="@/assets/img/mobile/managerCardRight.svg" alt="" class="position-absolute l-0 z-0  b-0">
                <img src="@/assets/img/mobile/managerCardLeft.svg" alt="" class="position-absolute r-0 t-0 z-0">

                <div class="position-relative id-card  d-flex flex-column justify-content-center text-center">
                    <img src="@/assets/img/mobile/managerCardX.svg" class="pointer close-icon" @click="close" alt="">
                   <div class="">
                    <img class="max-w-100" :src="require(`@/assets/img/mobile/${characterImg}`)" v-if="characterImg" alt="">
                    <p class="f-mobile-24 white bold mt-3 mb-2">{{ name }}</p>
                    <p class="f-mobile-22 white medium mb-2">{{ position }}</p>
                    <p class="f-mobile-20 white light mb-0 desc">{{ desc }}</p>

                   </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    emits: ['close'],
    props: {
        characterImg: {
            type: String
        },
        name: {
            type: String
        },
        desc: {
            type: String
        },
        position: {
            type: String
        },
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.id-card{
    width: 87.17vw;
    margin-left: auto;
    margin-right: auto;

}
.desc{
    line-height: 1.6;
}
.h-100vh {
    height: 100vh;
}
.close-icon{
    width: 35px;
}
.max-w-100{
    max-width: 100%;
    width: 90%;
}
@media (min-width: 567.98px) {
    .max-w-100{
    width: 70%!important;
    margin-left: auto;
    margin-right: auto;
}

}
</style>